import { Box,TextField,Select,MenuItem,OutlinedInput } from "@mui/material";
import { Form } from "react-router-dom";
import {citys} from "./Citys";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function PersonalInformation ({info, setInfo}) {
  return (
    <Box>
      <Form style={{
        marginTop: "20px",
        margin: '50px',
        width: '100%',
      }}>
        <Box 
          display="flex"
          flexDirection="column"
          gap={2}
          marginTop={2}
        >
          <Box>
            <TextField
              label="Téléphone"
              variant="outlined"
              value={info.phone}
              fullWidth={true}
              onChange={(e) => setInfo({ ...info, phone: e.target.value })}
              type="number"
            />
          </Box>
          <Box>
            <TextField
              label="CIN"
              variant="outlined"
              value={info.cin}
              fullWidth={true}
              onChange={(e) => setInfo({ ...info, cin: e.target.value })}
              type="text"
            />
          </Box>
          <Box>
            <label>Date de naissance</label>
            <TextField
              variant="outlined"
              value={info.birthday}
              fullWidth={true}
              onChange={(e) => setInfo({ ...info, birthday: e.target.value })}
              type="date"
            />
          </Box>
          <Box>
            <label> Select votre ville </label>
            <Select 
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              onChange={(e) => setInfo({ ...info, city: e.target.value })}
              value={info.city}
              fullWidth={true}
              input={<OutlinedInput label="Name" />}
              MenuProps={MenuProps}
            >
              {citys.map((city) => (
                <MenuItem
                  key={city.city}
                  value={city.city}
                >
                  {city.city}
              </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
      </Form>
    </Box>
  );
}