import {Box, Button, TextareaAutosize, TextField} from '@mui/material'
import '../../assets/style/contact.css'


export default function Contact() {
    return (
        <Box gap={2} className="contact">
            <Box className='contact_left'>
                <iframe 
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6882.318171112475!2d-9.54972!3d30.403228000000002!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xdb3b712e7b85cf9%3A0x4eb13d16ba344019!2sInstitut%20IMA!5e0!3m2!1sar!2sma!4v1724175401681!5m2!1sar!2sma" 
                    className='contact_map'
                    allowfullscreen="" loading="lazy" 
                    referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
            </Box>
            <Box className='contact_right'>
                <h1>Contactez-nous</h1>
                <Box className='contact_input'>
                    <label>Votre Email</label>
                    <TextField
                        fullWidth 
                        type='text'
                        placeholder='email'
                        className='email_contat'
                    />
                </Box>
                <Box className='contact_input'>
                    <label>Votre Telephone</label>
                    <TextField
                        fullWidth 
                        type='number'
                        placeholder='Phone'
                        className='email_contat'
                    />
                </Box>
                <Box className='contact_input message_contat'>
                    <label>Votre Message</label>
                    <TextField
                        fullWidth 
                        type='text'
                    />
                </Box>
                <Button
                    variant='contained'
                    fullWidth
                >
                    Envoyer un message
                </Button>
            </Box>
        </Box>
    );
}