import { backendApi } from "../../api";
import { uploadProfileImage } from "../../services/auth.services";

export const createCenter = async (info) => {
    try {
        const uploadfile = await uploadProfileImage(info.logo);
        let data = {
            name: info.name,
            city: info.city,
            logo: uploadfile.filename,
            fix  : info.fix,
            whatssap : info.whatssap,
            address : info.address
        }
        const response = await backendApi.post('/center/create/', data);
        if (response.status !== 201)
            return undefined;
        return response.data;
    } catch (error) {
        return undefined;
    }
}

export const getAllCenter = async () => {
    try {
        const res = await backendApi.get(`/center/all`);
        console.log("response_centers -> ", res)
        if (res.status !== 200)
            throw new Error("Ereur lors de la récupération des centres");
        return res.data.centers;
    } catch (error) {
        throw error;
    }
};