import { Box, Button, Container } from "@mui/material";
import LayoutWrraper from "../../../components/layout/LayoutWrraper";
import { useState, useEffect } from "react";
import { backendApi, imageApiUri } from "../../../../api";
import Cookies from "js-cookie";
import toast from "react-hot-toast";

export default function NotPayedMonthAgo () {
    const [data, setData] = useState([])
    useEffect(() => {
        const fetchData = async () => {
            const res  = await backendApi.get("/data/payments/not_payed/", {
                headers : {
                    token : Cookies.get("token")
                }
            })
            console.log(res)
            setData(res.data.data);
        }
        try {
            fetchData();
        } catch (err) {
            toast.error("faild to fetch data")
        }
    }, [])
    return (
        <LayoutWrraper>
            <Container 
                sx={{
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "left",
                    flexDirection: "column",
                    maxWidth: "100%",
                }}
            >
                <Box
                    display={"flex"}
                    flexDirection={"column"}
                    gap={"5px"}
                >
                    {/* 
                        todo : fetch data from this uri http://localhost:5000/data/payments/not_payed/
                    */}
                    {data.map((item, index) => {
                        console.log(item.profile.profileImage)
                        return (
                            <Box 
                                display={"flex"}
                                alignItems={"center"}
                                justifyContent={"space-between"}
                                bgcolor={"#e9f5f9"}
                                padding={1}
                                borderRadius={2}
                                key={index}>
                                <Box
                                    display={"flex"}
                                    alignItems={"center"}
                                    gap={2}
                                > 
                                    <img
                                        style={{
                                            width : "80px",
                                            height : "80px",
                                            objectFit : "cover",
                                            borderRadius : "50%"
                                        }} 
                                        src={imageApiUri + item.profile.profileImage} onError={(e) => {
                                        (
                                            (e.target.src =
                                              "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d3/Stadtbild_M%C3%BCnchen.jpg/2560px-Stadtbild_M%C3%BCnchen.jpg")
                                          )
                                    }} />
                                    <Box> 
                                        <p style={{ fontSize: 20 }}>{item.fullname}</p>
                                        <p>{item.email} - {item.profile.phone}</p>
                                    </Box>
                                </Box>
                                <Box>
                                    <Button >À porter sur Whatssap</Button>
                                </Box>
                            </Box>
                        )
                    })}
                </Box>
            </Container>
        </LayoutWrraper>
    );
}