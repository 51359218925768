import { Box, TextField } from "@mui/material";
import { Form } from "react-router-dom";

export default function AboutStudent ({info, setInfo}) {
  return (
    <Box>
      <Form style={{
        marginTop: "20px",
        margin: '50px',
        width: '100%',
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}  
    >
        <Box>
          <p > Slectionner une image de profile</p>
          <input
            type="file"
            variant="outlined"
            className="input-profile"
            onChange={(e) => setInfo({ ...info, profileImage: e.target.files[0] })}
          />
          <Box
            className="profile-image"
          >
            <img
              src={info.profileImage.name ? URL.createObjectURL(info.profileImage) : "https://icons.veryicon.com/png/o/business/back-stage-management/upload-pictures-1.png"}
              alt="profile"
              style={{ width: "100px", height: "100px" }}
            />
          </Box>
        </Box>
        <Box>
          <label> Niveau d'etude </label>
          <TextField
            variant="outlined"
            fullWidth={true}
            onChange={(e) => setInfo({ ...info, levelStudy: e.target.value })}
          />
        </Box>
        <Box>
          <label> Description (optionnel - créez votre propre identité)</label>
          <TextField
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            onChange={(e) => setInfo({ ...info, description: e.target.value })}
          />
        </Box>
      </Form>
    </Box>
  );
}