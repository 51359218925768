import {Box} from '@mui/material'

export default function Footer() {
    return (
        <Box
            bgcolor={"#09b5e1"}
            color={"white"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            width={"100%"}
            padding={5}
            flexWrap={"wrap"}
            marginTop={20}
        >
            <Box>
                {new Date().getFullYear()}@institute-ima.com
            </Box>
            <Box
                display={"flex"}
                alignItems={"center"}
                gap={2}
                color={"lightcyan"}

            >
                <p>0626-400022</p>
                <p>05282-39873</p>
            </Box>
        </Box>
    );
}