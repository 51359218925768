import { Box } from "@mui/material"
import LayoutWrraper from "./layout/LayoutWrraper"

export default function ErrorNotFound () {
  return (
    <LayoutWrraper>
      <Box 
        display={{ xs: 'flex', sm: 'flex' }}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        style={{
          height: '50vh'
        }}
      >
        <h1
          style={{
            fontSize: '3rem'
          }}
        >404</h1>
        <h1>Bonjour Admin, cette page n'existe pas.</h1>
      </Box>
    </LayoutWrraper>
  )
}