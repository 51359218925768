import { Box, Button, CircularProgress, Container } from "@mui/material";
import { useEffect, useState } from "react";
import { paymentCenterStatisticsService } from "../../../services/payments.services";
import Datepicker from "react-tailwindcss-datepicker";
import LayoutWrraper from "../../../components/layout/LayoutWrraper";
import SearchIcon from "@mui/icons-material/Search";
import toast from "react-hot-toast";
import Select from "react-select";
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid } from "recharts";
import { getAllCenter } from "../../../services/center.servies";
import dateFormat from "dateformat";
import moment from "moment";
import { StatisticsPayment } from "../../../components/StatisticsPayments";

export default function CentersStatistics() {
  const [payments, setPayments] = useState([]);
  const [centers, setCenters] = useState([]);
  const [center, setCenter] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const statisData = [];
  const [selectedData, setSelectedData] = useState([]);
  const [openedType, setOpendType] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [topPayments, setTopPayments] = useState([
    {
      name: "",
      Prix: 0,
    },
  ]);
  const [value, setValue] = useState({
    startDate: new Date().getDate(),
    endDate: new Date().setMonth(11),
  });
  const [data, setData] = useState({
    period_start: "",
    period_end: "",
    id: "",
  });
  const [oldPayments, setOldPayments] = useState([]);
  const [newPayments, setNewPayments] = useState([]);
  const handleModalClose = () => {
    setModalOpen(false);
  };
  const handleModalOpen = (data, type) => {
    setOpendType(type);
    setSelectedData(data);
    setModalOpen(true);
  };

  useEffect(() => {
    const getAllCenters = async () => {
      try {
        const res = await getAllCenter();
        setCenters(res);
      } catch (error) {
        toast.error("Je n'ai pas pu obtenir les centres");
      }
    };
    getAllCenters();
  }, []);
  const handleValueChange = (newValue) => {
    setData({
      ...data,
      period_start: newValue.startDate,
      period_end: newValue.endDate,
    });
    setValue(newValue);
  };

  const ff = [{ name: "Page A", uv: 400, pv: 2400, amt: 2400 }];
  const handleSearch = async () => {
    try {
      setIsLoading(true);
      const res = await paymentCenterStatisticsService(data);
      res.data.map((pay) => statisData.push(pay.amount));
      setTopPayments(
        res.data
          .filter((pay) => pay.amount > 1000)
          .map((pay) => ({
            name: pay?.user?.fullname.toUpperCase(),
            Prix: pay.amount,
          }))
      );
      const oldPayments = res.data.filter((pay) => {
        const dateAccountCreated = moment(pay?.user?.createdAt).format(
          "YYYY-MM-DD"
        );
        const now = moment(new Date()).format("YYYY-MM-DD");
        if (moment(now).diff(dateAccountCreated, "days") > 50) return pay;
      });
      setOldPayments(oldPayments);
      const newPayments = res.data.filter((pay) => {
        const dateAccountCreated = moment(pay?.user?.createdAt).format(
          "YYYY-MM-DD"
        );
        const now = moment(new Date()).format("YYYY-MM-DD");
        if (moment(now).diff(dateAccountCreated, "days") <= 50) return pay;
      });
      setNewPayments(newPayments);
      console.log("newPayments ---> ", newPayments);
      console.log("oldPayments ---> ", oldPayments);
      setPayments(res.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error("Je n'ai pas pu obtenir les paiements");
    }
  };
  return (
    <LayoutWrraper>
      <Container
        sx={{
          display: "flex",
          justifyContent: "left",
          alignItems: "left",
          flexDirection: "column",
          maxWidth: "100%",
        }}
      >
        {modalOpen && (
          <StatisticsPayment 
            data={selectedData}
            modalOpen={modalOpen}
            handleModalClose={handleModalClose}
            type={openedType}
          />)}
        <Box>
          <h1 className="font-bold text-3xl text-gray-800 mb-5">
            Statistiques des Paiements des instituts.
          </h1>
          <hr className="my-5 w-20 bg-inherit	" />
          <Box
            gap={2}
            display={"flex"}
            alignContent={"center"}
            justifyContent={"center"}
          >
            <Box flex={"3"}>
              <Datepicker value={value} onChange={handleValueChange} />
            </Box>
            <Box flex={"2"}>
              <Select
                options={centers.map((center) => ({
                  value: center._id,
                  label: center.name,
                }))}
                onChange={(e) => {
                  setCenter(center);
                  setData({
                    ...data,
                    id: e.value,
                  });
                }}
              />
            </Box>
            <Button
              style={{
                flex: "1",
              }}
              disabled={isLoading}
              variant="contained"
              color="primary"
              onClick={handleSearch}
            >
              {isLoading ? <CircularProgress /> : <SearchIcon />}
            </Button>
          </Box>
        </Box>
        <hr className="my-5" />
        <Box
          style={{
            maxWidth: "100%",
          }}
        >
          <h1 className="font-bold text-gray-800 mb-5">
            Statistiques des meilleurs paiements du
            <span className="text-blue-500"> {value.startDate}</span> au{" "}
            <span className="text-blue-500">{value.endDate}</span>
          </h1>
          <BarChart width={1000} margin={"0"} height={300} data={topPayments}>
            <XAxis dataKey="name" stroke="#8884d8" />
            <YAxis />
            <Tooltip wrapperStyle={{ width: 200, backgroundColor: "#ccc" }} />
            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
            <Bar dataKey="Prix" fill="#8884d8" barSize={30} />
          </BarChart>
        </Box>
        <hr className="my-5 w-20 bg-inherit	" />
        <Box
          display={"flex"}
          alignItems={"center"}
          width={"100%"}
          gap={5}
          flexWrap={"wrap"}
        >
          <Box
            onClick={() => handleModalOpen(oldPayments, "old")}
            style={{ cursor: "pointer" }}
            bgcolor={"springgreen"}
            padding={5}
            borderRadius={2}
            flex="1"
          >
            <h1 style={{ fontSize: "30px" }}>{oldPayments.length} </h1>
            <p>
              {String(
                "Les paiements totaux des anciens étudiants trouvés"
              ).toUpperCase()}
            </p>
          </Box>
          <Box
            onClick={() => handleModalOpen(newPayments, "new")}
            style={{ cursor: "pointer" }}
            bgcolor={"Highlight"}
            padding={5}
            borderRadius={2}
            flex="1"
          >
            <h1 style={{ fontSize: "30px" }}>{newPayments.length}</h1>
            <p>
              {String(
                "Les paiements totaux de nouveaux étudiants trouvés"
              ).toUpperCase()}
            </p>
          </Box>
        </Box>
        <hr className="my-5 w-20 bg-inherit	" />
        {payments.length > 0 ? (
          <Box
            style={{
              maxWidth: "100%",
              margintop: "20px",
            }}
          >
            <h1 className="font-bold text-gray-800 mb-5">
              Tout les paiements du
              <span className="text-blue-500"> {value.startDate}</span> au{" "}
              <span className="text-blue-500">{value.endDate}</span>
            </h1>
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={1}
              border={"1px solid #f9f9f9"}
              padding={"10px"}
              maxHeight={"600px"}
              style={{
                overflowY: "scroll",
              }}
            >
              {payments.map((pay, index) => (
                <Box
                  key={index}
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  bgcolor={"#f9f9f9"}
                  padding={"10px"}
                  borderRadius={"5px"}
                  minWidth={"500px"}
                >
                  <Box display={"flex"} alignItems={"center"}>
                    <h3 className="font-light mr-3 text-gray-800">
                      {pay?.user?.fullname}
                    </h3>
                    <p className="text-gray-500">
                      {new Date(pay.createdAt).toLocaleDateString()}
                    </p>
                  </Box>
                  <Box>
                    <p className="text-green-500 font-bold">{pay.amount} DH</p>
                  </Box>
                </Box>
              ))}
            </Box>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              bgcolor={"#fff"}
              borderRadius={"5px"}
              border={"1px solid #000"}
              padding={"10px"}
              mt={2}
            >
              <p className="text-gray-800 font-bold text-right">Total</p>
              <p className="text-green-800 font-bold text-right">
                {payments.reduce((acc, pay) => acc + pay.amount, 0)} DH
              </p>
            </Box>
          </Box>
        ) : (
          <Box>
            <h1 className="font-light text-gray-800 mb-5">
              Aucun paiement n'a été effectué pour cette période
            </h1>
          </Box>
        )}
      </Container>
    </LayoutWrraper>
  );
}
