import { Button, Container, Grid, TextField, Typography, Box, FormLabel, Select, MenuItem, OutlinedInput }  from '@mui/material';
import LayoutWrraper from '../../components/layout/LayoutWrraper';
import { useState } from 'react';
import { createCenter } from '../../services/center.servies';
import toast from 'react-hot-toast';
import { citys } from '../../../Components/profile/Citys';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const AddCenter = () => {
  const [info, setInfo] = useState({
    name: "",
    city : "",
    logo : {},
    fix  : 0,
    whatssap : 0,
    address : ""
  });
  const handleCreateCenter = async () => {
    try {
      console.log(info);
      const res = await createCenter(info);
      if (!res)
        throw ""
      toast.success("Le centre a été ajouté avec succès");
      setInfo({
        name: "",
        city : "",
        logo : {},
        fix : 0,
        whatssap : 0,
        address : ""
      })
    } catch (error) {
      toast.error("Erreur lors de l'ajout du centre, veuillez réessayer");
    }
  }
  return (
    <LayoutWrraper>
      <Container>
        <Box mt={3}>
          <Typography variant="h4" color="textPrimary">
            Ajouter un Centre de Formation
          </Typography>
        </Box>
        <Box mt={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormLabel>Ajouter un Nom de Centre</FormLabel>
              <TextField 
                fullWidth 
                label="Center Name"
                name="centerName" 
                variant="outlined" 
                onChange={(e) => setInfo({ ...info, name: e.target.value })}
                value={info.name}
              />
            </Grid>
            <Grid item xs={12}>
              <FormLabel>Ajouter Numero Fix</FormLabel>
              <TextField 
                fullWidth 
                type='number'
                variant="outlined" 
                onChange={(e) => setInfo({ ...info, fix: e.target.value })}
                value={info.fix}
              />
            </Grid>
            <Grid item xs={12}>
              <FormLabel>Ajouter Numero Whatssap</FormLabel>
              <TextField 
                fullWidth
                type='number'
                variant="outlined" 
                onChange={(e) => setInfo({ ...info, whatssap: e.target.value })}
                value={info.whatssap}
              />
            </Grid>
            <Grid item xs={12}>
              <FormLabel>Ajouter Address</FormLabel>
              <TextField 
                fullWidth
                type='text'
                variant="outlined" 
                onChange={(e) => setInfo({ ...info, address: e.target.value })}
                value={info.address}
              />
            </Grid>
            <Grid item xs={12}>
              <FormLabel>Slectionner une ville</FormLabel>
              <Select 
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                onChange={(e) => setInfo({ ...info, city: e.target.value })}
                value={info.city}
                fullWidth={true}
                input={<OutlinedInput label="Name" />}
                MenuProps={MenuProps}
              >
                {citys.map((city) => (
                  <MenuItem
                    key={city.city}
                    value={city.city}
                  >
                    {city.city}
                </MenuItem>
                ))}
              </Select>
            </Grid>

            <Grid item xs={12}>
              <Box>
                <p > Slectionner une image pour le logo du centre</p>
                <input
                  type="file"
                  variant="outlined"
                  className="input-profile"
                  onChange={(e) => setInfo({ ...info, logo: e.target.files[0] })}
                />
                <Box
                  className="profile-image"
                >
                  <img
                    src={info.logo.name ? URL.createObjectURL(info.logo) : "https://icons.veryicon.com/png/o/business/back-stage-management/upload-pictures-1.png"}
                    alt="profile"
                    style={{ width: "100px", height: "100px" }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Button 
                onClick={handleCreateCenter}
                color="primary" 
                variant="contained">
                Ajouter le Centre
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </LayoutWrraper>
  );
}