import { useEffect, useState } from "react";
import { backendApi } from "../api";
import Cookies from 'js-cookie';

export const resiterUser = async (data) => {
  try {
    const response = await backendApi.post("/user/create", data);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const isAuth = async () => {
  try {
    const tokenRes = await backendApi.get("/me");
    if (tokenRes.status === 200)
      return true;
  }catch (error) {
    console.log("[isAuth - error] ->> ", error)
    Cookies.remove("token");
    return false;
  }
}

export const authUser = async (data) => {
  try {
    const response = await backendApi.post("/login", data);
    console.log(response)
    if (response.status === 200)
      Cookies.set("access_token", response.data.token)
    else {
      Cookies.remove("access_token")
      throw response;
    }
  } catch (error) {
    Cookies.remove("access_token")
    throw error;
  }
}

//user info
export const getUser = async () => {
  try {
    const response = await backendApi.get("/me");
    if (response.status === 200)
      return response.data;
    else
      return undefined;
  } catch (error) {
    return undefined;
  }
}

export const logout = () => {
  Cookies.remove("access_token");
  window.location.reload();
}

export const uploadProfileImage = async (file) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    const response = await backendApi.post("/user/profile/upload/", formData, {
      headers: {
        "Content-Type": "multipart/form",
      }
    });
    if (response.status === 200)
      return response.data;
    else {
      console.log("response----> ", response);
      return undefined;
    }
  } catch (error) {
    return undefined;
  }
}

export const createProfile = async (data) => {
  try {
    const uploadProfileImageRes = await uploadProfileImage(data.profileImage);
    let newData = {
      ...data,
      profileImage: uploadProfileImageRes.filename
    }
    const response = await backendApi.post("/user/profile/create/", newData);
    console.log("respinse -> creatProfile -> ", response)
    if (response.status === 200)
      return response.data;
    else
      return undefined;
  } catch (error) {
    return undefined;
  }
}

export const IsAdmin = async () => {
  try {
    const response = await getUser();
    if (response) {
      if (response.role === "admin" || response.role === "super_admin")
        return true;
      else
        return false;
    }
    else
      return false;
  } catch (error) {
    return false;
  }
}


export const useAuth =  () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  useEffect(() => {
    const fetchMe = async () => {
      try {
        const res = await backendApi.get("/me");
        if (res.status != 200)
          setIsAuthenticated(false)
        else {
          setIsAuthenticated(true)
          console.log("result --> ", isAuthenticated)
        }
      } catch(err) {
        setIsAuthenticated(false)
      }
    }
    fetchMe();
  }, [])
  return {isAuthenticated};
}

export const useAdmin =  () => {
  const [isAdmin, setIsAdmin] = useState(null);
  useEffect(() => {
    const fetchMe = async () => {
      try {
        const res = await backendApi.get("/me");
        if (res.data.role === "admin" || res.data.role === "super_admin")
          setIsAdmin(true)
        else {
          setIsAdmin(false)
        }
      } catch(err) {
        setIsAdmin(false)
      }
    }
    fetchMe();
  }, [])
  return {isAdmin};
}

export const useSuperAdmin =  () => {
  const [isSuperAdmin, setIsSuperAdmin] = useState(null);
  useEffect(() => {
    const fetchMe = async () => {
      try {
        const res = await backendApi.get("/me");
        if (res.data.role === "super_admin")
          setIsSuperAdmin(true)
        else {
          setIsSuperAdmin(false)
        }
      } catch(err) {
        setIsSuperAdmin(false)
      }
    }
    fetchMe();
  }, [])
  return {isSuperAdmin};
}
