import { Box, OutlinedInput, Select, MenuItem, Badge } from "@mui/material";
import { Form } from "react-router-dom";
import { imageApiUri } from "../../api";
import { useEffect, useState } from "react";
import { backendApi } from "../../api";
import Cookies from "js-cookie";
import toast from "react-hot-toast";
import MailIcon from '@mui/icons-material/Mail';
import { fetchFormations } from "../../services/fetchFormations.services";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function CenterInformation({info, setInfo}) {
  const [centers, setCenters] = useState([]);
  const [formations, setFormations] = useState([]);
  const handleSlectCnter = (center) => {
    setInfo({ ...info, center: center });
  }

  useEffect(() => {
    const getAllCenters = async () => {
      try {
        const res = await backendApi.get(`/center/all`);
        setCenters(res.data.centers);
      } catch (error) {
        toast.error("Je n'ai pas pu obtenir les centres");
      }
    }
    const getAllFormations = async () => {
      try {
        const res = await fetchFormations();
        if (res === undefined) throw new Error("error");
        setFormations(res);
      } catch (error) {
        toast.error("Je n'ai pas pu obtenir les centres");
      }
    }
    getAllFormations();
    getAllCenters();
  }, [])
  return (
    <Box>
      <Form style={{
        marginTop: "20px",
        margin: '50px',
        width: '100%',
      }}>
        <Box>
          <label style={{
            fontSize: "20px",
            color: "gray",
          
          }}>Choisir votre center que vous appartenez</label>
          <Box
            display={"flex"}
            gap={2}
          >
            {centers.map((center, index) => (
              <Box
                key={index}
                style={{
                  userSelect: "none",
                  cursor: "pointer",
                  border : info.center === center._id ? "2px solid blue" : "none"
                }}
                display={"flex"}
                flexDirection={"column"}
                gap={2}
                alignItems={"center"}
                marginTop={2}
                justifyContent={"center"}
                bgcolor={"#f5f5f5"}
                width={"190px"}
                height={"190px"}
                borderRadius={"5px"}
                flexWrap={"wrap"}
                onClick={() => handleSlectCnter(center._id)}
                padding={2}
              >
                <img
                  src={`${imageApiUri}${center.logo}`}
                  alt={center.name} 
                  style={{width: "100px", height: "100px"}}
                />
                <p style={{
                  lineHeight: "0.5",
                  padding: 0,
                  margin: 0,
                }}> <strong> {center.name} </strong> </p>
                <p style={{
                  lineHeight: "0.5",
                  padding: 0,
                  margin: 0,
                }}>
                  {center.city}
                </p>
              </Box>
            ))}
          </Box>
        </Box>
        <Box marginTop={5}>
          <label style={{
            fontSize: "20px",
            color: "gray",
          }}>Choisir votre formation</label>
          <Select 
            labelId="demo-multiple-name-label"
            id="demo-multiple-name"
            value={info.formation || ""}
            onChange={(e) => setInfo({ ...info, formation: e.target.value })}
            fullWidth
            variant="outlined"
            input={<OutlinedInput label="Name" />}
            MenuProps={MenuProps}
          >
            {formations.map((formation, index) => (
              <MenuItem key={index} value={formation?._id}>
                {formation?.name}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Form>
    </Box>
  
  );
}