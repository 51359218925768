import LayoutWrraper from "../components/layout/LayoutWrraper";
import AddIcon from "@mui/icons-material/Add";
import { useState, useEffect, Fragment } from "react";
// import { getAllormation } from "../../services/formations.services";
import {
  Box,
  Button,
  Container,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { TableHeader } from "../components/TableHeader";
import Chip from "@mui/material/Chip";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
// import UpdateFormationModal from "../../components/UpdateFormationModal";
import toast from "react-hot-toast";
import { imageApiUri, backendApi } from "../../api";
import UpdateAdminPermissionModal from "../components/UpdateAdminPermissionModal";
import AddNewAdmin from "../components/AddNewAdmin";

const roles = {
  admin: "Administrateur Standard",
  super_admin: "Super Administrateur",
};

const TbaleBody = ({ admins, setAdmins }) => {
  const [open, setOpen] = useState(false);
  const [admin, setAdmin] = useState();
  const [validationText, setValidationText] = useState();
  console.log(admins)
  const handleOpen = (admin_data) => {
    setAdmin(admin_data);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setAdmin(null);
  };

  return (
    <>
      {admin && open && (
        <UpdateAdminPermissionModal
          setAdmins={setAdmins}
          handleModalClose={handleClose}
          modalOpen={open}
          admin={admin}
        />
      )}
      {admins.map((admin_, index) => (
        <tr key={index}>
          <td>
            <img
              style={{
                width: "60px",
                height: "60px",
              }}
              src={imageApiUri + admin_?.profile?.profileImage || ""}
              onError={(e) => {
                e.target.src = "https://media.istockphoto.com/id/871752462/vector/default-gray-placeholder-man.jpg?s=612x612&w=0&k=20&c=4aUt99MQYO4dyo-rPImH2kszYe1EcuROC6f2iMQmn8o="
              }}
            />
          </td>
          <td>{admin_?.fullname}</td>
          <td>{admin_?.email}</td>
          <td>
            {admin_?.profile?.center?.name || "None"}
          </td>
          <td>
            <Chip color={"primary"} label={roles[admin_?.role]} />
          </td>
          <td>
            <Chip
              color={admin_?.isActive ? "success" : "error"}
              label={`${
                admin_?.isActive ? "Compte actif" : "Compte désactivé"
              }`}
            />
          </td>
          <td>
            <Box display={"flex"} gap={1}>
              <Button
                onClick={() => handleOpen(admin_)}
                color="primary"
                variant="contained"
              >
                <ModeEditIcon />
              </Button>
              <Button
                color="error"
                variant="contained"
              >
                <DeleteIcon />
              </Button>
            </Box>
          </td>
        </tr>
      ))}
    </>
  );
};

export default function () {
  const [admins, setAdmins] = useState([]);
  const [filteredAdmins, setFilteredAdmins] = useState([]);
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [openPopupCreateAdmin, setOpenPopupCreateAdmin] = useState(false);
  useEffect(() => {
    const fetchAdmins = async () => {
      setIsLoading(true);
      try {
        const response = await backendApi.get("/admin/admins/");
        console.log(response);
        setAdmins(response.data);
        setFilteredAdmins(response.data);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        toast.error(error.response.data.message);
      }
    };
    fetchAdmins();
  }, []);

  const handleCloseCreateAdmin = () => {
    setOpenPopupCreateAdmin(false);
  };

  const onSearch = () => {
    const filterAdmins = admins.filter(
      (admin) =>
        admin.fullname.toLowerCase().includes(search.toLowerCase()) ||
        admin.email.toLowerCase().includes(search.toLowerCase())
    );
    setFilteredAdmins(filterAdmins);
  };

  return (
    <LayoutWrraper>
      <Container maxWidth="lg">
        {openPopupCreateAdmin && (
          <AddNewAdmin
            setAdmins={setAdmins}
            handleModalClose={handleCloseCreateAdmin}
            modalOpen={openPopupCreateAdmin}
          />
        )}
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Box>
            <h1 className="subpixel-antialiased font-bold">
              Gérer les administrateurs en un seul endroit
            </h1>
            <p className="antialiased font-light">
              Créez, supprimez et mettez à jour les permissions des
              administrateurs facilement.
            </p>
          </Box>
          <Button 
            onClick={() => setOpenPopupCreateAdmin(true)}
            variant="contained">
            <AddIcon /> <p>Ajouter</p>
          </Button>
        </Box>
        <hr style={{ marginTop: "10px", marginBottom: "10px" }} />
        {isLoading ? (
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            marginTop={"20%"}
            width={"100%"}
          >
            <CircularProgress />
          </Box>
        ) : (
          <div className="table_container">
            <input
              type="text"
              placeholder="Rechercher par nom, cin, email"
              className="search_table"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
                onSearch();
              }}
            />
            <table className="table_">
              <TableHeader
                headers={[
                  "Profile",
                  "Nom Complet",
                  "Email",
                  "Center",
                  "Rôle De L'utilisateur",
                  "Statut Du Compte",
                  "Action",
                ]}
              />
              <tbody>
                <TbaleBody
                  setAdmins={setAdmins}
                  admins={search.length > 0 ? filteredAdmins : admins}
                />
              </tbody>
            </table>
          </div>
        )}
      </Container>
    </LayoutWrraper>
  );
}
