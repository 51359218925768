import { 
  Box, 
  Button, 
  Modal, 
  TextField, 
  Typography, 
  Select, 
  MenuItem, 
  OutlinedInput, 
  CircularProgress} from "@mui/material";
import { useEffect, useState } from "react";
import { backendApi, imageApiUri } from "../../api";
import { fetchFormations } from "../../services/fetchFormations.services";
import toast from "react-hot-toast";
import Cookies from "js-cookie";
import cameraIcon from "../../assets/images/camera.png"
import {uploadProfileImage} from '../../services/auth.services'
import { getUserById } from "../services/users.services";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const updateProfileModalstyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-35%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  borderRadius : "10px",
  p: 4,
};

export default function UpdateStudentProfilePopup({handleClose, student, setStudent, modalOpen}) {
  const [data, setData] = useState({
    fullname : student?.fullname,
    center : student?.profile?.center?._id,
    phone : student?.profile?.phone,
    formation : student?.profile?.formation?._id,
    profileImage : student?.profile?.profileImage,
    profileID : student?.profile?._id,
    userID : student?._id
  });
  const [isLoading, setIsLoading] = useState(false)
  const [centers, setCenters] = useState([]);
  const [formations, setFormations] = useState([]);
  const [pfp, setPfp] = useState();

  useEffect(() => {
    const getAllCenters = async () => {
      try {
        const res = await backendApi.get(`/center/all/`);
        setCenters(res.data.centers);
      } catch (error) {
        toast.error("Je n'ai pas pu obtenir les centres");
      }
    }
    const getAllFormations = async () => {
      try {
        const res = await fetchFormations();
        if (res === undefined) throw new Error("error");
        setFormations(res);
      } catch (error) {
        toast.error("Je n'ai pas pu obtenir les centres");
      }
    }
    getAllCenters();
    getAllFormations();
  }, [])
  const handleUpdtae = async () => {
    setIsLoading(true)
    let updateData = data;
    try {
      if (pfp) {
        try {
          const res = await uploadProfileImage(pfp);
          updateData["profileImage"] = res.filename
        } catch(err) {
          toast.error(err.message);
        }
      }
      const res = await backendApi.post("/user/profile/update/", updateData, {
        headers : {
          token : Cookies.get("token")
        }
      })
      setIsLoading(false)
      if (res.status == 200) {
        toast.success(res.data.message)
        const student_ = await getUserById(student?._id);
        setStudent(student_);
        handleClose(true)
      }else
        throw res
    } catch (err) {
      setIsLoading(false)
      toast.error(err.message)
    }
  }
  const handleSlectCnter = (center) => {
    setData({ ...data, center: center });
  }
  return (
    <Modal
      keepMounted
      open={modalOpen}
      onClose={handleClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <Box sx={updateProfileModalstyle}>
        <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
          Modifier les informations de l'étudiant de{" "}
          {student?.fullname || "TELECHARGMENT..."}
        </Typography>
        <Typography
          color={"GrayText"}
          id="keep-mounted-modal-description"
          sx={{ mt: 2 }}
        >
          euillez fournir les nouvelles informations de l'étudiant dans les
          champs ci-dessous. Cliquez sur{" "}
          <span style={{ color: "black" }}>"Enregistrer" </span> pour
          sauvegarder les modifications.
        </Typography>
        <hr style={{ marginTop: 20, marginBottom: 20 }} />
        <Box
          display={"flex"}
          alignItems={"flex-start"}
        >
          <Box
            className="update_profile_upload_pfp"
            flex={0.5}
            position={"relative"}
          >
            <img
              style={{
                width: "90%",
                height: "90%",
                objectFit: "contain",
                borderRadius : "20px",
              }}
              src={pfp ? URL.createObjectURL(pfp) : imageApiUri + data?.profileImage} 
            />
            <Box className="profile_update_input_upload">
              <img
                style={{
                  width: "30%",
                  height: "30%",
                  objectFit: "contain",
                }}
                src={cameraIcon}
              />
              <input
                type="file" 
                onChange={(e) => setPfp(e.target.files[0])} 
              />
            </Box>
          </Box>
          <Box flex={1}>
            <Box marginBottom={3}>
              <TextField
                fullWidth
                label="Nom complet"
                value={data?.fullname || ""}
                onChange={(e) => setData({ ...data, fullname: e.target.value })}
                id="outlined-size-normal"
                defaultValue="Normal"
              />
            </Box>
            <Box marginBottom={3}>
              <TextField
                fullWidth
                label="Numero de Telephone"
                type="number"
                value={data?.phone || ""}
                onChange={(e) => setData({ ...data, phone: e.target.value })}
                id="outlined-size-normal"
                defaultValue="Normal"
              />
            </Box>
            <Box marginBottom={3}>
            <label>Center</label>
            <Box
              display={"flex"}
              gap={2}
              flexWrap={"wrap"}
            >
              {centers.map((center, index) => (
                <Box
                  key={index}
                  style={{
                    userSelect: "none",
                    cursor: "pointer",
                    border : data?.center === center?._id ? "2px solid blue" : "none"
                  }}
                  display={"flex"}
                  flexDirection={"column"}
                  gap={2}
                  alignItems={"center"}
                  marginTop={2}
                  justifyContent={"center"}
                  bgcolor={"#f5f5f5"}
                  width={"120px"}
                  height={"120px"}
                  borderRadius={"5px"}
                  onClick={() => handleSlectCnter(center?._id)}
                  padding={2}
                >
                  <img
                    src={`${imageApiUri}${center?.logo}`}
                    alt={center?.name} 
                    style={{width: "50%", height: "50%px", objectFit: "contain"}}
                  />
                  <p style={{
                    lineHeight: "0.5",
                    padding: 0,
                    margin: 0,
                  }}> <strong> {center?.name} </strong> </p>
                  <p style={{
                    lineHeight: "0.5",
                    padding: 0,
                    margin: 0,
                  }}>
                    {center?.city}
                  </p>
                </Box>
              ))}
            </Box>
          </Box>
            <Box>
              <label>Formation</label>
              <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                value={data?.formation}
                onChange={(e) => setData({ ...data, formation: e.target.value })}
                fullWidth
                variant="outlined"
                input={<OutlinedInput label="Name" />}
                MenuProps={MenuProps}
              >
                {formations.map((formation) => (
                  <MenuItem selected={formation?._id === data?.formation ? true : false} key={formation?.name} value={formation?._id}>
                    {formation?.name}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>
        </Box>
        {
          isLoading ? 
          <Button
            style={{marginTop: "20px"}} 
            fullWidth
            variant="contained"
            disabled>
            <CircularProgress />
          </Button> 
          : <Button
              style={{marginTop: "20px"}} 
              variant="contained"
              onClick={handleUpdtae}
              fullWidth>
                Enregistrer
            </Button>
        }
      </Box>
    </Modal>
  );
}
