import { 
    Box, 
    Button, 
    Container,
    Modal, 
    TextField, 
    Typography,
    Select, 
    MenuItem, 
    OutlinedInput, 
    CircularProgress} from '@mui/material';
import { useState } from 'react';
import { backendApi, imageApiUri } from '../../api';
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { citys } from '../../Components/profile/Citys';
import cameraIcon from '../../assets/images/camera.png'
import { uploadProfileImage } from '../../services/auth.services';
import { getAllCenter } from '../services/center.servies';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

export const updateStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-35%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    borderRadius : "10px",
    p: 4,
};

export default function UpdateFormationModal({handleModalClose, modalOpen, center, setCenters}) {
    const [info, setInfo] = useState(center)
    const [logo, setLogo] = useState()
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const handleUpdateCenter = async () => {
        setLoading(true)
        try {
            const data = {...info, centerID : center._id};
            if (logo) {
                const image_res = await uploadProfileImage(logo);
                data["logo"] = image_res.filename;
            }
            const res = await backendApi.post("/center/update/", data);
            toast.success(res.data.message)
            setLoading(false)
            const response_centers = await getAllCenter();
            setCenters(response_centers)
            handleModalClose(true)
        } catch (err) {
            setLoading(false)
            toast.error(err.message && err.message || err )
        }
    }
    return (
        <Modal 
            keepMounted
            open={modalOpen}
            onClose={handleModalClose}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
        >
           <Box sx={updateStyle}>
                <h2>Modifier les informations de cet Center</h2>
                <Box
                    marginTop={2}
                    display={"flex"}
                    alignItems={"flex-start"}
                >
                    <Box
                        className="update_profile_upload_pfp"
                        flex={0.5}
                        position={"relative"}
                    >
                        <img
                            style={{
                                width: "90%",
                                height: "90%",
                                objectFit: "contain",
                                borderRadius : "20px",
                            }}
                            src={logo ? URL.createObjectURL(logo) : imageApiUri + info.logo} 
                        />
                        <Box className="profile_update_input_upload">
                            <img
                                style={{
                                width: "30%",
                                height: "30%",
                                objectFit: "contain",
                                }}
                                src={cameraIcon}
                            />
                            <input
                                type="file" 
                                onChange={(e) => setLogo(e.target.files[0])} 
                            />
                        </Box>
                    </Box>
                    <Box flex={1} >
                        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                            <label htmlFor="title">Titre</label>
                            <TextField 
                                id="title" 
                                variant="outlined" 
                                label="Titre"
                                value={info.name} 
                                onChange={(e) => setInfo({ ...info, name: e.target.value })}
                            />
                        </Box>
                        <Box marginTop={2} sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                            <label htmlFor="duration">ville</label>
                            <Select 
                                id="city"
                                variant="outlined"
                                label="Ville"
                                onChange={(e) => setInfo({ ...info, city: e.target.value })}
                                value={info.city}
                                fullWidth={true}
                                input={<OutlinedInput label="Name" />}
                                MenuProps={MenuProps}
                            >
                                {
                                    citys.map((city, index) => (
                                        <MenuItem key={index} value={city.city}>{city.city}</MenuItem>
                                    ))
                                }
                            </Select>
                        </Box>
                        {loading ?
                            <Button
                                variant="contained"
                                disabled
                                style={{marginTop: "20px"}}
                                fullWidth
                            >
                                <CircularProgress />
                            </Button>
                        : (
                            <Button
                                onClick={handleUpdateCenter}
                                variant="contained"
                                style={{marginTop: "20px"}}
                                fullWidth
                            >
                                Enregistrer
                            </Button>
                        )}
                    </Box>
                </Box>
           </Box>
        </Modal>
    );
}