import { Box } from "@mui/material";
import '../../assets/style/banner.css'
import BannerBg from "../../assets/images/bg-light.png"
import img1 from "../../assets/images/1.jpeg"
import img2 from "../../assets/images/2.jpeg"
import img4 from "../../assets/images/4.jpeg"
import img5 from "../../assets/images/5.jpeg"
import img6 from "../../assets/images/6.jpeg"
import { Typewriter } from 'react-simple-typewriter';


import { Link } from "react-router-dom";
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
export default function Banner() {
    return (
        <Box className="banner_container">
            <img className="banner_bg" src={BannerBg} alt="banner background" />
            <Box position={"relative"} className="banner_left_items">
                <Box >
                    <h1 className="banner_title">INSTITUT-<Typewriter
                            words={['IMA', 'EPHEC']}
                            loop={5} // How many times to loop the animation
                            cursor
                            cursorStyle="_"
                            typeSpeed={500}
                            deleteSpeed={50}
                            delaySpeed={1000}
                            />
                            {"\n"}
                        AGADIR POUR LES FORMATIONS</h1>
                    <p className="banner_desc">
                        INSTITUT IMA STF de L'education et de formation professionnelle de renommée est fondé en 2016 par des lauréats de l’université. L’institut IMA de formation professionnelle de renommée est fondé en 2016 par des lauréats de l’université Ibn Zohr.
                    </p>
                    <Link className="banner_start_btn" to="/register">
                        <PlayCircleFilledIcon />
                        <p>Explorez maintenant</p>
                    </Link>
                </Box>
            </Box>
            <Box position={"relative"} className="banner_right_items">
                <img src={img2} />
                <img src={img4} />
                <img src={img5} />
                <img src={img6} />
            </Box>
        </Box>
    );
}