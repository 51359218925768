import LayoutWrraper from "../../components/layout/LayoutWrraper";
import { useState, useEffect, Fragment } from 'react';
import { 
  Box, 
  Button, 
  Container,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from '@mui/material';
import { TableHeader } from '../../components/TableHeader';
import Chip from '@mui/material/Chip';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import { getAllCenter } from "../../services/center.servies";
import toast from "react-hot-toast";
import { backendApi, imageApiUri } from "../../../api";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import dateFormat from "dateformat";
import UpdateCenterModal from '../../components/UpdateCenterModal'


const TbaleBody = ({ centers, setCenters }) => {
  const [open, setOpen] = useState(false);
  const [cent, setCent] = useState();
  const [showDeletPopup, setShowDeletePopup] = useState(false)
  const [deleteCenter, setDeleteCenter] = useState();
  const [validationText, setValidationText] = useState();

  const handleOpen = (center_data) => {
    setCent(center_data);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCent(null);
  };

  const showDeleteAlert = (cent) => {
    setDeleteCenter(cent);
    setShowDeletePopup(true)
  }

  const handleDelete = async () => {
    if (validationText != ("DELETE_CENTER_" + deleteCenter.name.toUpperCase()))  {
      toast.error("Le texte que vous avez entré ne correspond pas");
      return;
    }
    const data = {
      centerID : deleteCenter._id,
      confirmText : validationText,
      centerName : deleteCenter.name
    }
    try {
      const res = await backendApi.delete("/center/delete/", {
        data : data
      })
      toast.success(res.data.message);
      const response_centers = await getAllCenter();
      setCenters(response_centers)
      setShowDeletePopup(false)
    } catch(err) {
      toast.error(err.message);
    }
  }

  return (
    <>
      { cent && open && (
        <UpdateCenterModal
          setCenters={setCenters}
          handleModalClose={handleClose}
          modalOpen={open}
          center={cent}
        />
      )}
      {showDeletPopup && (
        <Fragment>
          <Dialog
            open={showDeletPopup}
            onClose={() => setShowDeletePopup(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Alerte de Suppression d'un Centre"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText  id="alert-dialog-description">
              Cher Administrateur,<br />
              Veuillez noter qu'un centre  a été sélectionné pour suppression. 
              Veuillez entrer le texte ci-dessus pour confirmer
              <p
                style={{
                  fontWeight: "bold",
                  color : "blue", 
                  userSelect: "none",
                  marginTop: 20
                }}
              >
                {String("DELETE_CENTER_" + deleteCenter?.name).toUpperCase()}
              </p>
              </DialogContentText>
              <TextField 
                  style={{ marginTop: 20 }}
                  fullWidth
                  id="Validation" 
                  variant="outlined" 
                  label="Entrer le texte"
                  onChange={(e) => setValidationText(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setShowDeletePopup(false)}
                variant="contained"
                autoFocus>Non</Button>
              <Button
                onClick={() => handleDelete()}
                color="error"
                variant="contained">
                Supprimer
              </Button>
            </DialogActions>
          </Dialog>
        </Fragment>
      )}
      {centers.map((center, index) => (
        <tr key={index}>
          <td>
            {center.logo && (
              <img
              style={{
                width:70,
                height: 70,
                borderRadius: "50%",
                objectFit: "cover"
              }} 
              src={`${imageApiUri}${center.logo}`}
              onError={(e) => {
                e.target.src = "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0xg_M3PlzLP_TlzY2HQE0X9HuYNesrvS39w&s"
              }}
            />
            ) || <CircularProgress />}
          </td>
          <td>{center?.name}</td>
          <td>
            <Chip
              color="info"
              icon={<LocationOnIcon />}
              label={`${center?.city}`}
            />
          </td>
          <td>
            {dateFormat(center?.createdAt, "mmmm d, yyyy, h:MM:ss TT")}</td>
          <td>
            <Box display={'flex'} gap={1}>
              <Button onClick={() => handleOpen(center)} color="primary" variant="contained">
                <ModeEditIcon />
              </Button>
              <Button 
                onClick={() => showDeleteAlert(center)}
                color="error" variant="contained">
                <DeleteIcon />
              </Button>
            </Box>
          </td>
        </tr>
      ))}
    </>
  );
};


export default function () {
  const [centers, setCenters] = useState([]);
  const [filteredCenters, setFilteredCenters] = useState([]);
  const [search, setSearch] = useState('');
  const [isLoaded, setIsLoaded] = useState(false)
  useEffect(() => {
    const fetchCenters = async () => {
      try {
        const response = await getAllCenter();
        console.log("response->display ==> ", response)
        setCenters(response);
        setFilteredCenters(response);
        setIsLoaded(true)
      } catch (error) {
        toast.error(error.message);
        setIsLoaded(true)
      }
    }
    fetchCenters();
  }, []);

  const onSearch = () => {
    setIsLoaded(false)
    const filterCenters = centers.filter((center) => (
      center.name.toLowerCase().includes(search.toLowerCase()) ||
      center.createdAt.includes(search.toLowerCase())
    ));
    setFilteredCenters(filterCenters);
    setIsLoaded(true)
  }

  return (
    <LayoutWrraper>
      <Container maxWidth='lg'>
        {!isLoaded ?           
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            marginTop={"20%"}
            width={"100%"}> 
              <CircularProgress /> 
          </Box> 
            : <div className='table_container'>
                <input
                  type='text'
                  placeholder="Rechercher par nom, cin, email"
                  className='search_table'
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                    onSearch()
                  }}
                />
                <table className='table_'>
                  <TableHeader headers={['Logo', 'Nom', 'Vill', 'Cree on', 'Action']} />
                  <tbody>
                    <TbaleBody setCenters={setCenters} centers={search.length > 0 ? filteredCenters : centers} />
                  </tbody>
                </table>
            </div>
        }
      </Container>
    </LayoutWrraper>
  );
}