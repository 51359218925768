import LayoutWrraper from '../../../components/layout/LayoutWrraper';
import { useState, useEffect } from 'react';
import { getAllUsers } from '../../../services/users.services';
import DoneIcon from '@mui/icons-material/Done';
import { TableHeader } from '../../../components/TableHeader';
import { UserApprove } from '../../../services/users.services';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Container } from '@mui/material';
import { imageApiUri } from '../../../../api';

const TbaleBody = ({ students, onApprove }) => {
  const navigate = useNavigate();
  const navigateTo = (id) => {
    navigate(`/admin/students/profile/${id}`);
  }
  return (
    <>
      {students.map((student, index) => (
        <tr key={index}>
          <td>
            <div className='table_student_info'>
              <img
                onError={(e) => {
                  e.target.src = "https://media.istockphoto.com/id/871752462/vector/default-gray-placeholder-man.jpg?s=612x612&w=0&k=20&c=4aUt99MQYO4dyo-rPImH2kszYe1EcuROC6f2iMQmn8o="
                }}
                src={imageApiUri + student?.profile?.profileImage}
                alt={student?.fullname}
              />
              <div>
                <h2>{student?.fullname}</h2>
                <p>{student?.email} • {student?.profile?.phone}</p>
              </div>
            </div>
          </td>
          <td>
            {student?.profile?.cin}
          </td>
          <td>
            {student?.profile?.formation?.name}
          </td>
          <td>
            {student?.profile?.center?.name}
          </td>
          <td>
            <div className='action_buttons'>
              <button
                onClick={() => onApprove(student._id)}
                className='btn_show'>
                <span className="material-symbols-outlined">
                  <DoneIcon />
                </span>
              </button>
              <button
                onClick={() => navigateTo(student._id)}
                style={{
                  backgroundColor: 'blue',
                  color: '#fff',
                  border: 'none',
                  cursor: 'pointer',
                  outline: 'none'

                }}
                className='btn_show'>
                <span className="material-symbols-outlined">
                  <VisibilityIcon />
                </span>
              </button>
            </div>
          </td>
        </tr>
      ))}
    </>
  );
}

export default function StudentNotApprovedList() {
  const [students, setStudents] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [search, setSearch] = useState('');
  useEffect(() => {
    const fetchStudents = async () => {
      const students = await getAllUsers();
      console.log(students);
      if (students) {
        setStudents(students.filter(student => student?.profile?.isApproved === false));
      }
    }
    fetchStudents();
  }, []);

  const onSearch = () => {
    const filteredStudents = students.filter(student => {
      return student.fullname.toLowerCase().includes(search.toLowerCase()) ||
        student.profile.cin.toLowerCase().includes(search.toLowerCase()) ||
        student.profile.phone.toLowerCase().includes(search.toLowerCase()) ||
        student.email.toLowerCase().includes(search.toLowerCase());
    });
    setFilteredStudents(filteredStudents.filter(student => student.profile.isApproved === false));
  }
  const onApprove = async (userId) => {
    try {
      const response = await UserApprove(userId);
      if (response) {
        toast.success('Utilisateur approuvé avec succès');
        const students = await getAllUsers();
        if (students) {
          setStudents(students.filter(student => student?.profile?.isApproved === false));
        }
      }
    } catch (error) {
      toast.error('Erreur lors de l\'approbation de l\'utilisateur');
    }
  }

  return (
    <LayoutWrraper>
      <Container maxWidth='lg'>
        <div className='table_container'>
          <input
            type='text'
            placeholder="Rechercher par nom, cin, email"
            className='search_table'
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
              onSearch()
            }}

          />
          <table className='table_'>
            {/* title par fransh */}
            <TableHeader headers={['Etudiant', 'Cin', 'Formation', 'Institut', 'Action']} />
            <tbody>
              <TbaleBody
                students={search ? filteredStudents : students}
                onApprove={onApprove}
              />
            </tbody>
          </table>
        </div>
      </Container>
    </LayoutWrraper>
  );
}