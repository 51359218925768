import { Box } from "@mui/material";
import '../../assets/style/missions.css'
import particip from  '../../assets/images/eng.jpeg'
import together from  '../../assets/images/together.jpeg'
import respo from  '../../assets/images/respo.jpeg'
import ethic from  '../../assets/images/ethic.jpeg'
const data = [
    {
        image : particip,
        title : "Participation active",
        desc : "L’institut s’est vu confier, la responsabilité d’assurer des formations de qualité aux jeunes étudiants de la ville d’Agadir et les préparer à la fois pour réussir leurs examens et à la fois pour assurer leurs avenirs  et booster leurs carrières académiques et professionnelles."
    },
    {
        image : together,
        title : "Ensemble Nous Réussissons",
        desc : "Travail en Groupe et Collaboration entre Étudiants, Professeurs et Associations reflète l'essence d'une approche intégrée et collaborative dans le milieu académique et au-delà. Cette phrase souligne l'importance d'unir les efforts de tous les acteurs impliqués dans le processus éducatif pour favoriser un environnement d'apprentissage enrichissant et dynamique"
    },
    {
        image : respo,
        title : "Responsabilité",
        desc : "Développer le sens de responsabilité chez les futurs professionnels et les managers vis-à-vis de leurs entreprises et de la société en général est essentiel pour préparer des leaders conscients et engagés"
    },
    {
        image : ethic,
        title : "ETHIQUE",
        desc : "Citoyenneté, Tolérance, Respect des Droits et Obligations, Responsabilité Sociale et Diversité."
    }
]
export default function Missions() {
    return (
        <Box className="missions">
            <Box className="missions_header">
                <h1 className="missions_title">Sa Missions</h1>
                <p className="missions_title">
                L’institut s’est vu confier, la responsabilité d’assurer des formations de qualité aux jeunes étudiants de la ville d’Agadir et les préparer à la fois pour réussir leurs examens et à la fois pour assurer leurs avenirs et booster leurs carrières académiques et professionnelles.
                </p>
            </Box>
            <Box className="missions_cards">
                {data.map((item, index) => (
                    <Box key={index} className="missions_card">
                        <img src={item.image} />
                        <h2>{item.title}</h2>
                        <p>
                            {item.desc}
                        </p>
                    </Box>
                ))}
            </Box>
        </Box>
    );
}