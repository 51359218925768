import { useEffect, useState } from "react";
import LayoutWrraper from "../components/layout/LayoutWrraper";
import {
  Box,
  Button,
  Container,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import cameraIcon from "../../assets/images/camera.png";
import settingsIcon from "../../assets/images/settings.png";
import { getUser, uploadProfileImage } from "../../services/auth.services";
import toast from "react-hot-toast";
import { backendApi, imageApiUri } from "../../api";

export default function AdminSettings() {
  const [pfp, setPfp] = useState();
  const [admin, setAdmin] = useState({});
  const [isUserLoading, setIsUserLoading] = useState(false);
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [info, setInfo] = useState({
    email: "",
    fullname: "",
    oldPassword: "",
    newPassowrd: "",
  });

  useEffect(() => {
    const fetchUser = async () => {
      try {
        setIsUserLoading(true);
        const resp = await getUser();
        if (!resp)
          throw "Impossible d'obtenir les données administratives du serveur";
        setAdmin(resp);
        setInfo({ ...info, fullname: resp?.fullname, email: resp?.email });
        setIsUserLoading(false);
      } catch (err) {
        toast.err(err);
        setIsUserLoading(false);
      }
    };
    fetchUser();
  }, []);

  const handleUpdate = async () => {
    try {
      setIsUpdateLoading(true);
      if (pfp) {
        const file_res = await uploadProfileImage(pfp);
        if (file_res) {
          info["profileImage"] = file_res.filename;
        }else {
          toast.error("Échec de l'enregistrement de l'image du profil")
          setPfp(null)
          setIsUpdateLoading(false);
          return;
        }
      }
      console.log(info);
      const res = await backendApi.post("/admin/update/", info);
      console.log(res)
      toast.success(res.data.message);
      setIsUpdateLoading(false);
    } catch (err) {
      console.log(err)
      setIsUpdateLoading(false);
      toast.error(err.response.data.message || err);
    }
  };
  return (
    <LayoutWrraper>
      <Container>
        {isUserLoading ? (
          <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
            <CircularProgress />
          </Box>
        ) : (
          <Box>
            <Box marginBottom={2} gap={1} display={"flex"} alignItems={"center"}>
                <img
                style={{
                    width: "40px",
                    height: "40px",
                }}
                src={settingsIcon}
                />
                <Box>
                <h1 style={{ fontWeight: "bold" }}>
                    Mettre à jour vos données administratives
                </h1>
                <p style={{ fontWeight: "lighter", color: "gray" }}>
                    Mettez à jour votre image de profil, mot de passe, etc.
                </p>
                </Box>
            </Box>
            <Box display={"flex"} alignItems={"flex-start"} marginTop={3}>
              <Box
                className="update_profile_upload_pfp"
                flex={0.5}
                position={"relative"}
              >
                <img
                  style={{
                    border: "1px solid lightgray",
                    width: "90%",
                    maxHeight: "200px",
                    objectFit: "contain",
                    borderRadius: "20px",
                  }}
                  src={
                    (pfp && URL.createObjectURL(pfp)) ||
                    imageApiUri + admin?.profile?.profileImage
                  }
                  onError={(e) => {
                    e.target.src = "https://media.istockphoto.com/id/871752462/vector/default-gray-placeholder-man.jpg?s=612x612&w=0&k=20&c=4aUt99MQYO4dyo-rPImH2kszYe1EcuROC6f2iMQmn8o="
                  }}
                />
                <Box
                  style={{
                    width: "90%",
                    maxHeight: "200px",
                    //   maxWidth: "200px",
                  }}
                  className="profile_update_input_upload"
                >
                  <img
                    style={{
                      width: "30%",
                      height: "30%",
                      objectFit: "contain",
                    }}
                    src={cameraIcon}
                  />
                  <input
                    style={{
                      width: "90%",
                      maxHeight: "200px",
                      maxWidth: "200px",
                    }}
                    type="file"
                    onChange={(e) => setPfp(e.target.files[0])}
                  />
                </Box>
              </Box>
              <Box flex={1}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                  <TextField
                    id="fulename"
                    variant="outlined"
                    label="Nom et Prenom"
                    value={info.fullname}
                    onChange={(e) =>
                      setInfo({ ...info, fullname: e.target.value })
                    }
                  />
                </Box>
                <Box
                  sx={{
                    marginTop: "20px",
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                  }}
                >
                  <TextField
                    id="email"
                    variant="outlined"
                    label="Email"
                    value={info.email}
                    onChange={(e) =>
                      setInfo({ ...info, email: e.target.value })
                    }
                  />
                </Box>
                <Box
                  sx={{
                    marginTop: "20px",
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                  }}
                >
                  <TextField
                    id="password"
                    variant="outlined"
                    label="Mot De Pass"
                    onChange={(e) =>
                      setInfo({ ...info, oldPassword: e.target.value })
                    }
                  />
                </Box>
                <Box
                  sx={{
                    marginTop: "20px",
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                  }}
                >
                  <TextField
                    id="newPassword"
                    variant="outlined"
                    label="Nevaux Mot de pass"
                    onChange={(e) =>
                      setInfo({ ...info, newPassowrd: e.target.value })
                    }
                  />
                </Box>
                <Button
                  fullWidth
                  style={{
                    marginTop: "30px",
                  }}
                  disabled={isUpdateLoading}
                  variant="contained"
                  onClick={handleUpdate}
                >
                  {isUpdateLoading ? <CircularProgress /> : "sauvegarder"}
                </Button>
              </Box>
            </Box>
          </Box>
        )}
      </Container>
    </LayoutWrraper>
  );
}
