import toast from "react-hot-toast";
import { backendApi } from "../../api";
import Cookies from 'js-cookie';

export const fetchMe = async () => {
  const res = await backendApi.get("/me");
  return res.data;
}


export const getAllUsers = async () => {
  try {
    const response = await backendApi.get(`/users/`);
    if (response.status !== 200)
      return undefined;
    return response.data;
  } catch (error) {
    return undefined;
  }
}

export const UserApprove = async (userId) => {
  try {
    const data = {userId};
    const response = await backendApi.post('/user/approve', data);
    if (response.status !== 200)
      return undefined;
    return response.data;
  }
  catch (error) {
    return undefined;
  }
}

export const getUserById = async (userId) => {
  try {
    const response = await backendApi.get(`/user/id/${userId}`);
    if (response.status !== 200)
      return undefined;
    return response.data;
  } catch (error) {
    return undefined;
  }
}
