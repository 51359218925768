import {
  Box,
  Button,
  Container,
  Modal,
  TextField,
  Typography,
  Select,
  MenuItem,
  OutlinedInput,
  CircularProgress,
} from "@mui/material";
import { useState } from "react";
import { backendApi } from "../../api";
import toast from "react-hot-toast";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const updateStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-35%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  borderRadius : "10px",
  p: 4,
};

export default function UpdateAdminPermissionModal({handleModalClose,modalOpen,admin,setAdmins, }) {
  const [info, setInfo] = useState({
    adminID : admin?._id,
    role : admin?.role,
    isActive : admin?.isActive,
  });
  const [loading, setLoading] = useState(false);

  const handleUpdateAdmin = async () => {
    setLoading(true);
    try {
      const res = await backendApi.post("/admin/update/permission", info);
      const response_admins = await backendApi.get("/admin/admins/");
      setAdmins(response_admins.data);
      toast.success(res.data.message);
      setLoading(false);
      handleModalClose(true);
    } catch (err) {
      setLoading(false);
      toast.error((err.message && err.message) || err);
    }
  };
  return (
    <Modal
      keepMounted
      open={modalOpen}
      onClose={handleModalClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <Box sx={updateStyle}>
        <h2>Modifier les informations de cet Admin</h2>
        <Box>
          <Box
            marginTop={2}
            sx={{ display: "flex", flexDirection: "column", gap: 2 }}
          >
            <label htmlFor="role">Rôle De L'utilisateur</label>
            <Select
              id="role"
              variant="outlined"
              label="Rôle De L'utilisateur"
              onChange={(e) => setInfo({ ...info, role: e.target.value })}
              value={info.role}
              fullWidth={true}
              input={<OutlinedInput label="Rôle De L'utilisateur" />}
              MenuProps={MenuProps}
            >
                <MenuItem  value={"admin"}>
                    Administrateur Standard
                </MenuItem>
                <MenuItem value={"super_admin"}>
                    Super Administrateur
                </MenuItem>
            </Select>
          </Box>
          <Box
            marginTop={2}
            sx={{ display: "flex", flexDirection: "column", gap: 2 }}
          >
            <label htmlFor="role">Statut Du Compte</label>
            <Select
              id="role"
              variant="outlined"
              label="Statut Du Compte"
              onChange={(e) => setInfo({ ...info, isActive: e.target.value })}
              value={info.isActive}
              fullWidth={true}
              input={<OutlinedInput label="Statut Du Compte" />}
              MenuProps={MenuProps}
            >
                <MenuItem value={false}>
                    Désactivé
                </MenuItem>
                <MenuItem value={true}>
                    Actif
                </MenuItem>
            </Select>
          </Box>
            <Button
              disabled={loading}
              onClick={handleUpdateAdmin}
              variant="contained"
              style={{ marginTop: "20px" }}
              fullWidth
            >
              {loading ? <CircularProgress /> : "Enregistrer"}
            </Button>
        </Box>
      </Box>
    </Modal>
  );
}
