export const citys = [
  {
    "city": "Casablanca", 
    "lat": "33.5333", 
    "lng": "-7.5833", 
    "country": "Morocco", 
    "iso2": "MA", 
    "admin_name": "Casablanca-Settat", 
    "capital": "admin", 
    "population": "3499000", 
    "population_proper": "3499000"
  }, 
  {
    "city": "Fès", 
    "lat": "34.0433", 
    "lng": "-5.0033", 
    "country": "Morocco", 
    "iso2": "MA", 
    "admin_name": "Fès-Meknès", 
    "capital": "admin", 
    "population": "1112072", 
    "population_proper": "1112072"
  }, 
  {
    "city": "Marrakech", 
    "lat": "31.6300", 
    "lng": "-8.0089", 
    "country": "Morocco", 
    "iso2": "MA", 
    "admin_name": "Marrakech-Safi", 
    "capital": "admin", 
    "population": "966987", 
    "population_proper": "966987"
  }, 
  {
    "city": "Tangier", 
    "lat": "35.7767", 
    "lng": "-5.8039", 
    "country": "Morocco", 
    "iso2": "MA", 
    "admin_name": "Tanger-Tétouan-Al Hoceïma", 
    "capital": "admin", 
    "population": "947952", 
    "population_proper": "947952"
  }, 
  {
    "city": "Sale", 
    "lat": "34.0333", 
    "lng": "-6.8000", 
    "country": "Morocco", 
    "iso2": "MA", 
    "admin_name": "Rabat-Salé-Kénitra", 
    "capital": "", 
    "population": "850403", 
    "population_proper": "850403"
  }, 
  {
    "city": "Rabat", 
    "lat": "34.0209", 
    "lng": "-6.8416", 
    "country": "Morocco", 
    "iso2": "MA", 
    "admin_name": "Rabat-Salé-Kénitra", 
    "capital": "primary", 
    "population": "572717", 
    "population_proper": "572717"
  }, 
  {
    "city": "Meknès", 
    "lat": "33.8950", 
    "lng": "-5.5547", 
    "country": "Morocco", 
    "iso2": "MA", 
    "admin_name": "Fès-Meknès", 
    "capital": "", 
    "population": "520428", 
    "population_proper": "520428"
  }, 
  {
    "city": "Oujda-Angad", 
    "lat": "34.6867", 
    "lng": "-1.9114", 
    "country": "Morocco", 
    "iso2": "MA", 
    "admin_name": "Oriental", 
    "capital": "admin", 
    "population": "494252", 
    "population_proper": "494252"
  }, 
  {
    "city": "Kenitra", 
    "lat": "34.2500", 
    "lng": "-6.5833", 
    "country": "Morocco", 
    "iso2": "MA", 
    "admin_name": "Rabat-Salé-Kénitra", 
    "capital": "", 
    "population": "431282", 
    "population_proper": "431282"
  }, 
  {
    "city": "Agadir", 
    "lat": "30.4333", 
    "lng": "-9.6000", 
    "country": "Morocco", 
    "iso2": "MA", 
    "admin_name": "Souss-Massa", 
    "capital": "admin", 
    "population": "421844", 
    "population_proper": "421844"
  }, 
  {
    "city": "Tétouan", 
    "lat": "35.5667", 
    "lng": "-5.3667", 
    "country": "Morocco", 
    "iso2": "MA", 
    "admin_name": "Tanger-Tétouan-Al Hoceïma", 
    "capital": "", 
    "population": "380787", 
    "population_proper": "380787"
  }, 
  {
    "city": "Taourirt", 
    "lat": "34.4169", 
    "lng": "-2.8850", 
    "country": "Morocco", 
    "iso2": "MA", 
    "admin_name": "Oriental", 
    "capital": "", 
    "population": "321876", 
    "population_proper": "321876"
  }, 
  {
    "city": "Temara", 
    "lat": "33.9267", 
    "lng": "-6.9122", 
    "country": "Morocco", 
    "iso2": "MA", 
    "admin_name": "Rabat-Salé-Kénitra", 
    "capital": "", 
    "population": "313510", 
    "population_proper": "313510"
  }, 
  {
    "city": "Safi", 
    "lat": "32.2833", 
    "lng": "-9.2333", 
    "country": "Morocco", 
    "iso2": "MA", 
    "admin_name": "Marrakech-Safi", 
    "capital": "", 
    "population": "308508", 
    "population_proper": "308508"
  }, 
  {
    "city": "Khénifra", 
    "lat": "32.9394", 
    "lng": "-5.6675", 
    "country": "Morocco", 
    "iso2": "MA", 
    "admin_name": "Béni Mellal-Khénifra", 
    "capital": "", 
    "population": "228567", 
    "population_proper": "117510"
  }, 
  {
    "city": "El Jadid", 
    "lat": "33.2333", 
    "lng": "-8.5000", 
    "country": "Morocco", 
    "iso2": "MA", 
    "admin_name": "Casablanca-Settat", 
    "capital": "", 
    "population": "220181", 
    "population_proper": "220181"
  }, 
  {
    "city": "Laâyoune", 
    "lat": "27.1536", 
    "lng": "-13.2033", 
    "country": "Morocco", 
    "iso2": "MA", 
    "admin_name": "Laâyoune-Sakia El Hamra", 
    "capital": "", 
    "population": "217732", 
    "population_proper": "217732"
  }, 
  {
    "city": "Mohammedia", 
    "lat": "33.6833", 
    "lng": "-7.3833", 
    "country": "Morocco", 
    "iso2": "MA", 
    "admin_name": "Casablanca-Settat", 
    "capital": "", 
    "population": "208612", 
    "population_proper": "208612"
  }, 
  {
    "city": "Kouribga", 
    "lat": "32.8833", 
    "lng": "-6.9167", 
    "country": "Morocco", 
    "iso2": "MA", 
    "admin_name": "Béni Mellal-Khénifra", 
    "capital": "", 
    "population": "196196", 
    "population_proper": "196196"
  }, 
  {
    "city": "Béni Mellal", 
    "lat": "32.3394", 
    "lng": "-6.3608", 
    "country": "Morocco", 
    "iso2": "MA", 
    "admin_name": "Béni Mellal-Khénifra", 
    "capital": "admin", 
    "population": "192676", 
    "population_proper": "192676"
  }, 
  {
    "city": "Ait Melloul", 
    "lat": "30.3342", 
    "lng": "-9.4972", 
    "country": "Morocco", 
    "iso2": "MA", 
    "admin_name": "Souss-Massa", 
    "capital": "", 
    "population": "171847", 
    "population_proper": "171847"
  }, 
  {
    "city": "Nador", 
    "lat": "35.1667", 
    "lng": "-2.9333", 
    "country": "Morocco", 
    "iso2": "MA", 
    "admin_name": "Oriental", 
    "capital": "", 
    "population": "161726", 
    "population_proper": "161726"
  }, 
  {
    "city": "Taza", 
    "lat": "34.2167", 
    "lng": "-4.0167", 
    "country": "Morocco", 
    "iso2": "MA", 
    "admin_name": "Fès-Meknès", 
    "capital": "", 
    "population": "148456", 
    "population_proper": "148456"
  }, 
  {
    "city": "Settat", 
    "lat": "33.0000", 
    "lng": "-7.6167", 
    "country": "Morocco", 
    "iso2": "MA", 
    "admin_name": "Casablanca-Settat", 
    "capital": "", 
    "population": "142250", 
    "population_proper": "142250"
  }, 
  {
    "city": "Barrechid", 
    "lat": "33.2667", 
    "lng": "-7.5833", 
    "country": "Morocco", 
    "iso2": "MA", 
    "admin_name": "Casablanca-Settat", 
    "capital": "", 
    "population": "136634", 
    "population_proper": "136634"
  }, 
  {
    "city": "Al Khmissat", 
    "lat": "33.8167", 
    "lng": "-6.0667", 
    "country": "Morocco", 
    "iso2": "MA", 
    "admin_name": "Rabat-Salé-Kénitra", 
    "capital": "", 
    "population": "131542", 
    "population_proper": "131542"
  }, 
  {
    "city": "Inezgane", 
    "lat": "30.3658", 
    "lng": "-9.5381", 
    "country": "Morocco", 
    "iso2": "MA", 
    "admin_name": "Souss-Massa", 
    "capital": "", 
    "population": "130333", 
    "population_proper": "130333"
  }, 
  {
    "city": "Ksar El Kebir", 
    "lat": "35.0090", 
    "lng": "-5.9000", 
    "country": "Morocco", 
    "iso2": "MA", 
    "admin_name": "Tanger-Tétouan-Al Hoceïma", 
    "capital": "", 
    "population": "126617", 
    "population_proper": "126617"
  }, 
  {
    "city": "My Drarga", 
    "lat": "30.3800", 
    "lng": "-9.4800", 
    "country": "Morocco", 
    "iso2": "MA", 
    "admin_name": "Souss-Massa", 
    "capital": "", 
    "population": "126512", 
    "population_proper": "126512"
  }, 
  {
    "city": "Larache", 
    "lat": "35.1833", 
    "lng": "-6.1500", 
    "country": "Morocco", 
    "iso2": "MA", 
    "admin_name": "Tanger-Tétouan-Al Hoceïma", 
    "capital": "", 
    "population": "125008", 
    "population_proper": "125008"
  }, 
  {
    "city": "Guelmim", 
    "lat": "28.9833", 
    "lng": "-10.0667", 
    "country": "Morocco", 
    "iso2": "MA", 
    "admin_name": "Guelmim-Oued Noun", 
    "capital": "admin", 
    "population": "118318", 
    "population_proper": "118318"
  }, 
  {
    "city": "Berkane", 
    "lat": "34.9167", 
    "lng": "-2.3167", 
    "country": "Morocco", 
    "iso2": "MA", 
    "admin_name": "Oriental", 
    "capital": "", 
    "population": "109237", 
    "population_proper": "109237"
  }, 
  {
    "city": "Ad Dakhla", 
    "lat": "23.7081", 
    "lng": "-15.9456", 
    "country": "Morocco", 
    "iso2": "MA", 
    "admin_name": "Dakhla-Oued Ed-Dahab", 
    "capital": "", 
    "population": "106277", 
    "population_proper": "106277"
  }, 
  {
    "city": "Bouskoura", 
    "lat": "33.4489", 
    "lng": "-7.6486", 
    "country": "Morocco", 
    "iso2": "MA", 
    "admin_name": "Casablanca-Settat", 
    "capital": "", 
    "population": "103026", 
    "population_proper": "103026"
  }, 
  {
    "city": "Al Fqih Ben Çalah", 
    "lat": "32.5000", 
    "lng": "-6.5333", 
    "country": "Morocco", 
    "iso2": "MA", 
    "admin_name": "Béni Mellal-Khénifra", 
    "capital": "", 
    "population": "102019", 
    "population_proper": "102019"
  }, 
  {
    "city": "Oued Zem", 
    "lat": "32.8667", 
    "lng": "-6.5667", 
    "country": "Morocco", 
    "iso2": "MA", 
    "admin_name": "Béni Mellal-Khénifra", 
    "capital": "", 
    "population": "95267", 
    "population_proper": "95267"
  }, 
  {
    "city": "Sidi Slimane", 
    "lat": "34.2600", 
    "lng": "-5.9200", 
    "country": "Morocco", 
    "iso2": "MA", 
    "admin_name": "Rabat-Salé-Kénitra", 
    "capital": "", 
    "population": "92989", 
    "population_proper": "92989"
  }, 
  {
    "city": "Errachidia", 
    "lat": "31.9319", 
    "lng": "-4.4244", 
    "country": "Morocco", 
    "iso2": "MA", 
    "admin_name": "Drâa-Tafilalet", 
    "capital": "admin", 
    "population": "92374", 
    "population_proper": "92374"
  }, 
  {
    "city": "Guercif", 
    "lat": "34.2333", 
    "lng": "-3.3667", 
    "country": "Morocco", 
    "iso2": "MA", 
    "admin_name": "Oriental", 
    "capital": "", 
    "population": "90880", 
    "population_proper": "90880"
  }, 
  {
    "city": "Oulad Teïma", 
    "lat": "30.4000", 
    "lng": "-9.2167", 
    "country": "Morocco", 
    "iso2": "MA", 
    "admin_name": "Souss-Massa", 
    "capital": "", 
    "population": "89387", 
    "population_proper": "89387"
  }, 
  {
    "city": "Ben Guerir", 
    "lat": "32.2300", 
    "lng": "-7.9500", 
    "country": "Morocco", 
    "iso2": "MA", 
    "admin_name": "Marrakech-Safi", 
    "capital": "", 
    "population": "88626", 
    "population_proper": "88626"
  }, 
  {
    "city": "Sefrou", 
    "lat": "33.8300", 
    "lng": "-4.8300", 
    "country": "Morocco", 
    "iso2": "MA", 
    "admin_name": "Fès-Meknès", 
    "capital": "", 
    "population": "79887", 
    "population_proper": "79887"
  }, 
  {
    "city": "Fnidq", 
    "lat": "35.8500", 
    "lng": "-5.3500", 
    "country": "Morocco", 
    "iso2": "MA", 
    "admin_name": "Tanger-Tétouan-Al Hoceïma", 
    "capital": "", 
    "population": "77436", 
    "population_proper": "77436"
  }, 
  {
    "city": "Sidi Qacem", 
    "lat": "34.2167", 
    "lng": "-5.7000", 
    "country": "Morocco", 
    "iso2": "MA", 
    "admin_name": "Rabat-Salé-Kénitra", 
    "capital": "", 
    "population": "75672", 
    "population_proper": "75672"
  }, 
  {
    "city": "Tiznit", 
    "lat": "29.7167", 
    "lng": "-9.7167", 
    "country": "Morocco", 
    "iso2": "MA", 
    "admin_name": "Souss-Massa", 
    "capital": "", 
    "population": "74699", 
    "population_proper": "74699"
  }, 
  {
    "city": "Moulay Abdallah", 
    "lat": "33.1978", 
    "lng": "-8.5883", 
    "country": "Morocco", 
    "iso2": "MA", 
    "admin_name": "Casablanca-Settat", 
    "capital": "", 
    "population": "74671", 
    "population_proper": "74671"
  }, 
  {
    "city": "Youssoufia", 
    "lat": "32.2500", 
    "lng": "-8.5333", 
    "country": "Morocco", 
    "iso2": "MA", 
    "admin_name": "Marrakech-Safi", 
    "capital": "", 
    "population": "67628", 
    "population_proper": "67628"
  }, 
  {
    "city": "Martil", 
    "lat": "35.6167", 
    "lng": "-5.2667", 
    "country": "Morocco", 
    "iso2": "MA", 
    "admin_name": "Tanger-Tétouan-Al Hoceïma", 
    "capital": "", 
    "population": "64355", 
    "population_proper": "64355"
  }, 
  {
    "city": "Aïn Harrouda", 
    "lat": "33.6372", 
    "lng": "-7.4483", 
    "country": "Morocco", 
    "iso2": "MA", 
    "admin_name": "Casablanca-Settat", 
    "capital": "", 
    "population": "62420", 
    "population_proper": "62420"
  }, 
  {
    "city": "Souq Sebt Oulad Nemma", 
    "lat": "32.2948", 
    "lng": "-6.7007", 
    "country": "Morocco", 
    "iso2": "MA", 
    "admin_name": "Béni Mellal-Khénifra", 
    "capital": "", 
    "population": "60076", 
    "population_proper": "60076"
  }, 
  {
    "city": "Skhirate", 
    "lat": "33.8500", 
    "lng": "-7.0300", 
    "country": "Morocco", 
    "iso2": "MA", 
    "admin_name": "Rabat-Salé-Kénitra", 
    "capital": "", 
    "population": "59775", 
    "population_proper": "59775"
  }, 
  {
    "city": "Ouezzane", 
    "lat": "34.8000", 
    "lng": "-5.5833", 
    "country": "Morocco", 
    "iso2": "MA", 
    "admin_name": "Tanger-Tétouan-Al Hoceïma", 
    "capital": "", 
    "population": "59606", 
    "population_proper": "59606"
  }, 
  {
    "city": "Al Hoceïma", 
    "lat": "35.2472", 
    "lng": "-3.9322", 
    "country": "Morocco", 
    "iso2": "MA", 
    "admin_name": "Tanger-Tétouan-Al Hoceïma", 
    "capital": "", 
    "population": "56716", 
    "population_proper": "56716"
  }, 
  {
    "city": "M’diq", 
    "lat": "35.6858", 
    "lng": "-5.3253", 
    "country": "Morocco", 
    "iso2": "MA", 
    "admin_name": "Tanger-Tétouan-Al Hoceïma", 
    "capital": "", 
    "population": "56227", 
    "population_proper": "56227"
  }, 
  {
    "city": "Midalt", 
    "lat": "32.6800", 
    "lng": "-4.7300", 
    "country": "Morocco", 
    "iso2": "MA", 
    "admin_name": "Drâa-Tafilalet", 
    "capital": "", 
    "population": "55304", 
    "population_proper": "55304"
  }, 
  {
    "city": "Azrou", 
    "lat": "33.4417", 
    "lng": "-5.2247", 
    "country": "Morocco", 
    "iso2": "MA", 
    "admin_name": "Fès-Meknès", 
    "capital": "", 
    "population": "54350", 
    "population_proper": "54350"
  }, 
  {
    "city": "El Kelaa des Srarhna", 
    "lat": "32.0481", 
    "lng": "-7.4083", 
    "country": "Morocco", 
    "iso2": "MA", 
    "admin_name": "Marrakech-Safi", 
    "capital": "", 
    "population": "51404", 
    "population_proper": "51404"
  }, 
  {
    "city": "Qasbat Tadla", 
    "lat": "32.6000", 
    "lng": "-6.2667", 
    "country": "Morocco", 
    "iso2": "MA", 
    "admin_name": "Béni Mellal-Khénifra", 
    "capital": "", 
    "population": "47343", 
    "population_proper": "47343"
  },  
  {
    "city": "Jerada", 
    "lat": "34.3117", 
    "lng": "-2.1636", 
    "country": "Morocco", 
    "iso2": "MA", 
    "admin_name": "Oriental", 
    "capital": "", 
    "population": "43506", 
    "population_proper": "43506"
  }, 
  {
    "city": "El Aïoun", 
    "lat": "34.5852", 
    "lng": "-2.5056", 
    "country": "Morocco", 
    "iso2": "MA", 
    "admin_name": "Oriental", 
    "capital": "", 
    "population": "41832", 
    "population_proper": "41832"
  }, 
  {
    "city": "Azemmour", 
    "lat": "33.2878", 
    "lng": "-8.3422", 
    "country": "Morocco", 
    "iso2": "MA", 
    "admin_name": "Casablanca-Settat", 
    "capital": "", 
    "population": "40920", 
    "population_proper": "40920"
  }, 
  {
    "city": "Temsia", 
    "lat": "30.3600", 
    "lng": "-9.4140", 
    "country": "Morocco", 
    "iso2": "MA", 
    "admin_name": "Souss-Massa", 
    "capital": "", 
    "population": "40780", 
    "population_proper": "40780"
  }, 
  {
    "city": "Zagora", 
    "lat": "30.3306", 
    "lng": "-5.8381", 
    "country": "Morocco", 
    "iso2": "MA", 
    "admin_name": "Drâa-Tafilalet", 
    "capital": "", 
    "population": "40067", 
    "population_proper": "40067"
  }, 
  {
    "city": "Ait Ourir", 
    "lat": "31.5644", 
    "lng": "-7.6628", 
    "country": "Morocco", 
    "iso2": "MA", 
    "admin_name": "Marrakech-Safi", 
    "capital": "", 
    "population": "39108", 
    "population_proper": "39108"
  }, 
  {
    "city": "Aziylal", 
    "lat": "31.9669", 
    "lng": "-6.5694", 
    "country": "Morocco", 
    "iso2": "MA", 
    "admin_name": "Béni Mellal-Khénifra", 
    "capital": "", 
    "population": "38520", 
    "population_proper": "38520"
  }, 
  {
    "city": "Biougra", 
    "lat": "30.2144", 
    "lng": "-9.3708", 
    "country": "Morocco", 
    "iso2": "MA", 
    "admin_name": "Souss-Massa", 
    "capital": "", 
    "population": "37933", 
    "population_proper": "37933"
  }, 
  {
    "city": "El Hajeb", 
    "lat": "33.6928", 
    "lng": "-5.3711", 
    "country": "Morocco", 
    "iso2": "MA", 
    "admin_name": "Fès-Meknès", 
    "capital": "", 
    "population": "35282", 
    "population_proper": "35282"
  }, 
]