import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar';
import Header from '../Header';
import '../../assets/admin.css'
import { isAuth, IsAdmin } from '../../../services/auth.services';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import Cookies from 'js-cookie';

function LayoutWrraper(props) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div id="get_scrol" className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            <div className='dashboard'> 
              {props.children}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default LayoutWrraper