import Cookies from "js-cookie";
import { backendApi } from "../../api";

export const CreateFormationHandler = async (data) => {
    try {
        const response = await backendApi.post("/formations/create/", data);
        if (response.status >= 200 && response.status <= 204)
            return response.data;
        else //error messages on fr
            throw new Error("Une erreur s'est produite lors de la création de la formation");
    } catch (error) {
        throw error;
    }
}

export const getAllormation = async () => {
    try {
        const response = await backendApi.get("/formations/all/");
        if (response.status >= 200 && response.status <= 204)
            return response.data;
        else {

            throw new Error("Une erreur s'est produite lors de la récupération des formations");
        }
    }
    catch (error) {
        throw error;
    }
}