import React, { useEffect, useState } from 'react';

import WelcomeBanner from '../components/dashboard/WelcomeBanner';
import DashboardCard10 from '../components/dashboard/DashboardCard10';
import LayoutWrraper from '../components/layout/LayoutWrraper';
import { backendApi } from '../../api';
import toast from 'react-hot-toast';
import moment from 'moment';
import { Box, CircularProgress } from '@mui/material';
import { Link } from 'react-router-dom';

function Dashoard(props) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [user, setUser] = useState({});
  const [students, setSudents] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [formations, setFormations] = useState([]);
  const [centres, setCenters] = useState([]);
  useEffect(() => {
     const fetchMe = async () => {
      setIsLoading(true);
      try {
        const res = await backendApi.get("/me");
        if (res.status != 200)
          throw "";
        setUser(res.data);
        setIsLoading(false);
       } catch(err) {
        setIsLoading(false);
        toast.error("N'a pas obtenu vos données");
       }
     }
     fetchMe();

     const getStudents = async () => {
      setIsLoading(true);
      try {
        const res = await backendApi.get("/users");
        if (res.status != 200)
          throw ""
          setAllUsers(res.data);
          const lastStudents = res.data.filter(student => {
              if (!student || !student?.profile) 
                return false;
              const studentCreatedAt = moment(student?.profile?.createdAt);
              const now = moment();
              const daysDifference = now.diff(studentCreatedAt, "days");
              return daysDifference <= 7;
          });
        setSudents(lastStudents);
        console.log(lastStudents)
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        toast.error("Faild pour obtenir les derniers étudiants")
      }
     }
     getStudents();

     const getFormation = async () => {
      setIsLoading(true)
        try {
          const res = await backendApi.get("/formations/all");
          if (res.status != 200)
            throw ""
          setFormations(res.data);
          setIsLoading(false)
        } catch (err) {
          setIsLoading(false)
          toast.error("N'a pas réussi à obtenir toutes les formations")
        }
     }
     getFormation();

     const getCenters = async () => {
      setIsLoading(true)
        try {
          const res = await backendApi.get("/center/all");
          if (res.status != 200)
            throw ""
          setCenters(res.data.centers);
          setIsLoading(false)
        } catch (err) {
          setIsLoading(false)
          toast.error("n'a pas réussi à obtenir tous les centres")
        }
     }
     getCenters();
  }, [])
  return (
    <LayoutWrraper>
      <WelcomeBanner user={user}  />
      <Box 
        display={"flex"}
        alignItems={"center"}
        gap={5}
        marginBottom={5} 
        marginTop={5}>
        <Link
          to={"/admin/students"}
          style={{...style, backgroundColor : 'green'}}
        >
          <h2 style={{fontSize : 30}}>{isLoading ? <CircularProgress color="inherit" /> : allUsers.length}</h2>
          <p>Étudiants totaux</p>
        </Link>
        <Link
          to={"/admin/formations/all"}
          style={{...style, backgroundColor : "lightblue", color : "black"}}
        >
          <h2 style={{fontSize : 30}}>{isLoading ? <CircularProgress color="inherit" /> : formations.length}</h2>
          <p>Formations totaux</p>
        </Link>

        <Link
          to={"/admin/instituts/lists"}
          style={{...style, backgroundColor : "blue", color : "white"}}
        >
          <h2 style={{fontSize : 30}}>{isLoading ?  <CircularProgress color="inherit" /> : centres.length}</h2>
          <p>Instituts totaux</p>
        </Link>
      </Box>
      <DashboardCard10 lastStudents={students} isLoading={isLoading}  />
    </LayoutWrraper>
  );
}

const style = {
  height: "100px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  paddingLeft: "10px",
  borderRadius: "10px",
  width: "200px",
  color: "white",
}

export default Dashoard