import moment from 'moment';
import React from 'react';
import { imageApiUri } from '../../../api';
import { CircularProgress } from '@mui/material';

function DashboardCard10({lastStudents, isLoading}) {
  return (
    <div className="col-span-full xl:col-span-6 bg-white shadow-lg rounded-sm border border-slate-200">
      <header className="px-5 py-4 border-b border-slate-100">
        <h2 className="font-semibold text-slate-800">Étudiants inscrits pendant 7 derniers jours</h2>
      </header>
      <div className="p-3">
        <div className="overflow-x-auto">
          {isLoading ? <CircularProgress /> : (
            <table className="table-auto w-full">
            {/* Table header */}
            <thead className="text-xs font-semibold uppercase text-slate-400 bg-slate-50">
              <tr>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">Image Profile</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">Nom et prénom</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">Téléphone</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">Formation</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">Inscrit à</div>
                </th>
              </tr>
            </thead>
            {/* Table body */}
            <tbody className="text-sm divide-y divide-slate-100">
              {
                lastStudents.map(student => {
                  return (
                    <tr key={student?._id}>
                      <td className="p-2 whitespace-nowrap">
                        <div className="flex items-center">
                          <div className="w-10 h-10 shrink-0 mr-2 sm:mr-3">
                            <img 
                              className="rounded-full" 
                              src={imageApiUri + student?.profile?.profileImage} 
                              onError={(e) => {
                                e.target.src = "https://media.istockphoto.com/id/871752462/vector/default-gray-placeholder-man.jpg?s=612x612&w=0&k=20&c=4aUt99MQYO4dyo-rPImH2kszYe1EcuROC6f2iMQmn8o="
                              }}
                              style={{
                                width : "100px",
                                height : "100px",
                                maxHeight : "100%",
                                borderRadius : "50%",
                                objectFit : "cover"
                              }}
                              alt={student?.fullname} />
                          </div>
                          {/* <div className="font-medium text-slate-800">{student?.fullname}</div> */}
                        </div>
                      </td>
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left">{student?.fullname || "non"}</div>
                      </td>
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left">{student?.profile?.phone || "No phone"}</div>
                      </td>
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left font-medium text-green-500">{student?.profile?.formation?.name || "No formation"}</div>
                      </td>
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left font-medium text-blue-500">{moment(student?.profile?.createdAt, "").format('L')}</div>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
          )}
        </div>

      </div>
    </div>
  );
}

export default DashboardCard10;
