import { Box, Button, CircularProgress } from "@mui/material";
import dateFormat, { masks } from "dateformat";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { imageApiUri } from "../../../../api";
import NoEncryptionGmailerrorredIcon from "@mui/icons-material/NoEncryptionGmailerrorred";
import LayoutWrraper from "../../../components/layout/LayoutWrraper";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  UserApprove,
  fetchMe,
  getUserById,
} from "../../../services/users.services";
import toast from "react-hot-toast";
import { PaymentPopup, UpdatePaymentPopup } from "./PaymentPopup";
import {
  createPayment,
  updatePayment,
} from "../../../services/payments.services";
import * as React from "react";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import UpdateStudentProfilePopup from "../../../components/UpdateStudentProfilePopup";
import PrintIcon from "@mui/icons-material/Print";
import Invoice from "../../../components/Invoice";
import { jsPDF } from "jspdf";

import html2canvas from "html2canvas";
import ReactDOMServer from "react-dom/server";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const leftItemStyles = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "white",
  borderRadius: "10px",
  boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
  padding: "10px",
  flex: "0.5",
  maxHeight: "500px",
};

const rightItemStyles = {
  backgroundColor: "white",
  borderRadius: "10px",
  boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
  padding: "30px",
  height: "auto",
};

const ProfileImage = ({ student }) => {
  return (
    <img
      src={

        student?.profile?.profileImage
          ? `${imageApiUri}${student?.profile?.profileImage}`
          : "https://via.placeholder.com/150"
      }
      onError={(e) => {
        e.target.src = "https://media.istockphoto.com/id/871752462/vector/default-gray-placeholder-man.jpg?s=612x612&w=0&k=20&c=4aUt99MQYO4dyo-rPImH2kszYe1EcuROC6f2iMQmn8o="
      }}
      alt={student?.fullname}
      style={{
        width: "100px",
        height: "100px",
        borderRadius: "50%",
      }}
    />
  );
};

const pStyle = {
  color: "grey",
  fontSize: "1rem",
  marginTop: "10px",
  marginBottom: "5px",
};

const BasicInfo = ({ student }) => {
  return (
    <Box style={rightItemStyles}>
      <h2 className="text-3xl mb-2">Basic Information</h2>
      <Box
        display={{ xs: "block", sm: "grid" }}
        gridTemplateColumns={{ xs: "1fr", sm: "1fr 1fr 1fr" }}
        gap={2}
      >
        <Box>
          <p style={pStyle}>Age</p>
          <p>
            {" "}
            {new Date().getFullYear() -
              new Date(student?.profile?.birthday).getFullYear()}{" "}
            years old
          </p>
        </Box>
        <Box>
          <p style={pStyle}>Date de naissance</p>
          <p> {dateFormat(student?.profile?.birthday, masks["fullDate"])}</p>
        </Box>
        <Box>
          <p style={pStyle}>Téléphone</p>
          <p>
            {student?.profile?.phone.startsWith("+212") ||
            student?.profile?.phone?.startsWith("212")
              ? student?.profile?.phone
              : "+212 " + student?.profile?.phone.slice(1)}
          </p>
        </Box>
        <Box>
          <p style={pStyle}>Email</p>
          <p>{student?.email}</p>
        </Box>
        <Box>
          <p style={pStyle}>City</p>
          <p>{student?.profile?.city}</p>
        </Box>
        <Box>
          <p style={pStyle}>Cin</p>
          <p>{student?.profile?.cin}</p>
        </Box>
        <Box>
          <p style={pStyle}>Niveau d'étude</p>
          <p>{student?.profile?.levelStudy}</p>
        </Box>
      </Box>
    </Box>
  );
};

const CenterInfo = ({ student }) => {
  return (
    <Box style={rightItemStyles}>
      <h2 className="text-3xl mb-2"> Votre Centre </h2>
      <Box
        // grid
        display={{ xs: "block", sm: "grid" }}
        gridTemplateColumns={{ xs: "1fr", sm: "1fr 1fr 1fr" }}
        gap={2}
      >
        <Box>
          <p style={pStyle}>Nome de centre</p>
          <p> {student?.profile?.center?.name?.toUpperCase()}</p>
        </Box>
        <Box>
          <p style={pStyle}>Formation</p>
          <p> {student?.profile?.formation?.name}</p>
        </Box>
        <Box>
          <p style={pStyle}>La date de registration</p>
          <p> {dateFormat(student?.profile?.createdAt, masks["fullDate"])} </p>
        </Box>
        <Box>
          <p style={pStyle}> Fin de formation (appréciation)</p>
          <p>
            {" "}
            {dateFormat(
              new Date(student?.profile?.createdAt).setMonth(
                new Date(student?.profile?.createdAt).getMonth() + 3 //3 need to change to current duration
              ),
              masks["fullDate"]
            )}{" "}
          </p>
        </Box>
      </Box>
    </Box>
  );
};

export const monthsTextStyle = [
  "Premier mois",
  "Deuxième mois",
  "Troisième mois",
  "Quatrième mois",
  "Cinquième mois",
  "Sixième mois",
  "Septième mois",
  "Huitième mois",
  "Neuvième mois",
  "Dixième mois",
  "Onzième mois",
  "Douzième mois",
];

const PaymentTable = ({ student, setStudent, isSuperAdmin }) => {
  const [open, setOpen] = React.useState(false);
  const [editOpen, setEditOpen] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [invoiceIndex, setInvoiceIndex] = useState();
  const [info, setInfo] = useState({
    amount: 0,
    formation: student?.profile?.formation?._id,
    userId: student?._id,
    center: student?.profile?.center?._id,
    month: "",
    status: "pending",
    paymentDate: "",
    paymentId: "",
  });
  const [openInvoiceModal, setOpenInvoicedModal] = useState(false);
  const [selectedInvoicepayment, setSelectedInvoicepayment] = useState({}); //to know which payment you want to print her invoice

  const onCreatePayment = async () => {
    try {
      setInfo({ ...info, formation: student?.profile?.formation?._id });
      setInfo({ ...info, center: student?.profile?.center?._id });
      console.table(student?.profile?.formation);
      setIsLoading(true);
      const res = await createPayment(info);
      console.log("isLoadung ---> ", isLoading);
      if (res) {
        toast.success("Paiement effectué avec succès");
        setInfo({
          amount: 0,
          formation: student?.profile?.formation?._id,
          userId: student?._id,
          center: student?.profile?.center?._id,
          month: "",
          status: "pending",
          paymentDate: "",
        });
        setOpen(false);
        const response = await getUserById(student._id);
        setStudent(response);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(error.message);
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handlEditeClickOpen = (payment) => {
    setEditOpen(true);
    setInfo({
      paymentId: payment._id,
      amount: payment.amount,
      formation: payment.formation,
      userId: payment.user,
      center: payment.center,
      month: payment.month,
      status: payment.status,
      paymentDate: dateFormat(payment.paymentDate, masks["isoDate"]),
    });
  };

  const onUpdatePayment = async () => {
    try {
      setIsLoading(true);
      const resp = updatePayment(info);
      if (resp) {
        setInfo({
          amount: 0,
          formation: student?.profile?.formation?._id,
          userId: student?._id,
          center: student?.profile?.center?._id,
          month: "",
          status: "pending",
          paymentDate: "",
        });
        const response = await getUserById(student._id);
        setStudent(response);
        toast.success("Paiement modifié avec succès");
        setIsLoading(false);
        setEditOpen(false);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(error.message);
    }
  };
  const [pdfLoadin, setPdfLoading] = useState({});
  const generatePDF = async (index) => {
    try {
      setPdfLoading((prev) => ({ ...prev, [index]: true }));
      const element = document.getElementById("invoice");
      const elementWidth = 966;
      const elementHeight = 787;
      const scale = 2;
      const canvasWidth = elementWidth + 200;
      const canvasHeight = elementHeight + 200;
      const canvas = await html2canvas(element, {
        scale: scale,
        width: elementWidth,
        height: elementHeight,
        useCORS: true,
        windowWidth: "100%",
      });
      setOpenInvoicedModal(false);
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidth = 210;
      const imgHeight = (canvasHeight * imgWidth) / canvasWidth;
      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      pdf.save("facture.pdf");
      toast.success("La Facture téléchargée avec succès");
      setPdfLoading((prev) => ({ ...prev, [index]: false }));
    } catch (err) {
      setOpenInvoicedModal(false);
      setPdfLoading((prev) => ({ ...prev, [index]: false }));
      toast.error(err);
    }
  };
  const handleOpenInvoice = (payment_, index) => {
    setInvoiceIndex(index);
    setSelectedInvoicepayment(payment_);
    setOpenInvoicedModal(true);
    setTimeout(() => {
      generatePDF(index);
    }, 1);
  };

  const handleInvoiceModalClose = () => {
    setOpenInvoicedModal(false);
  };
  return (
    <Box style={rightItemStyles}>
      {openInvoiceModal && (
        <Invoice
          student={student}
          payment={selectedInvoicepayment}
          handleModalClose={handleInvoiceModalClose}
          modalOpen={openInvoiceModal}
          invoiceIndex={invoiceIndex}
        />
      )}
      <h2 className="text-3xl mb-2"> {student?.fullname} Paiements </h2>
      <TableContainer
        component={Paper}
        style={{
          marginTop: "20px",
        }}
      >
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Payment ID</StyledTableCell>
              <StyledTableCell align="left">Mois</StyledTableCell>
              <StyledTableCell align="left">Amount</StyledTableCell>
              <StyledTableCell align="left">Status</StyledTableCell>
              <StyledTableCell align="left">Date</StyledTableCell>
              <StyledTableCell align="left">Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {student?.payments.length === 0 ? (
              <StyledTableRow>
                <StyledTableCell
                  style={{
                    padding: "20px",
                    color: "grey",
                    backgroundColor: "white",
                  }}
                >
                  {student?.fullname} Pas encore effectué de paiement
                </StyledTableCell>
              </StyledTableRow>
            ) : (
              student?.payments?.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell component="th" scope="row">
                    {row._id}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {monthsTextStyle[row.month - 1]}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.amount} DH
                  </StyledTableCell>
                  <StyledTableCell align="left">{row.status}</StyledTableCell>
                  <StyledTableCell align="left">
                    {dateFormat(row.paymentDate, masks["mediumDate"])}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {isSuperAdmin && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handlEditeClickOpen(row)}
                        style={{
                          marginRight: "10px",
                        }}
                      >
                        <ModeEditIcon />
                      </Button>
                    )}
                    <Button
                      onClick={() => handleOpenInvoice(row, index)}
                      disabled={pdfLoadin[index]}
                      variant="contained"
                      color="success"
                    >
                      {pdfLoadin[index] ? (
                        <CircularProgress size={25} />
                      ) : (
                        <PrintIcon />
                      )}
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <UpdatePaymentPopup
        student={student}
        info={info}
        setInfo={setInfo}
        onCreatePayment={onUpdatePayment}
        open={editOpen}
        setOpen={setEditOpen}
        isLoading={isLoading}
      />
      <Box
        width={"100%"}
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        padding={"20px"}
        backgroundColor={"#f5f5f5"}
        borderRadius={"10px"}
        marginTop={"20px"}
      >
        <Box>Total Payé</Box>
        <Box>
          {student?.payments.reduce((acc, curr) => acc + curr.amount, 0)} DH
        </Box>
      </Box>
      <Box>
        <Button
          variant="contained"
          color="inherit"
          onClick={handleClickOpen}
          style={{
            marginTop: "20px",
          }}
        >
          Effectuer un paiement
        </Button>
        <PaymentPopup
          student={student}
          info={info}
          setInfo={setInfo}
          onCreatePayment={onCreatePayment}
          open={open}
          setOpen={setOpen}
          isLoading={isLoading}
        />
      </Box>
    </Box>
  );
};

export default function Profile() {
  const { userId } = useParams();
  const [student, setStudent] = useState({});
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);

  const navigate = useNavigate();
  useEffect(() => {
    const fetchAdmin = async () => {
      try {
        const res = await fetchMe();
        if (res.role === "super_admin") setIsSuperAdmin(true);
        else setIsSuperAdmin(false);
      } catch (err) {
        toast.error("Échec de l'obtention des données d'administration");
      }
    };
    const fetchUserData = async () => {
      try {
        const response = await getUserById(userId);
        if (!response) {
          navigate("/admin/404");
        }
        setStudent(response);
        setLoading(false);
      } catch (error) {
        console.error(error);
        navigate("/admin/404");
        setLoading(false);
      }
    };
    fetchAdmin();
    fetchUserData();
  }, []);
  const [isLoadinApprove, setIsLoadingAprove] = useState(false);
  const onApprove = async (userId) => {
    try {
      setIsLoadingAprove(true);
      const response = await UserApprove(userId);
      console.log(response);
      if (response) {
        const res = await getUserById(userId);
        setStudent(res);
        setIsLoadingAprove(false);
        toast.success(response.message);
      } else {
        setIsLoadingAprove(false);
        throw new Error("Error approving user");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  return (
    <LayoutWrraper>
      {loading ? (
        <Box
          display={{ xs: "flex", sm: "flex" }}
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          style={{
            height: "50vh",
            fontSize: "1.5rem",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box
          display={{ xs: "flex", sm: "flex" }}
          flexDirection={{ xs: "column", sm: "row" }}
          height={"100%"}
          gap={2}
          marginTop={2}
        >
          <Box style={leftItemStyles}>
            <ProfileImage student={student} />
            <p
              style={{
                marginTop: "20px",
              }}
            >
              {student?.fullname?.toUpperCase()}
            </p>
            <p
              style={{
                marginTop: "20px",
                color: "grey",
                fontSize: "0.8rem",
                textAlign: "center",
              }}
            >
              {student?.profile?.description}
            </p>
            {student?.profile?.isApproved === false ? (
              <Box
                display={"flex"}
                alingItems={"center"}
                justifyContent={"center"}
                flexDirection={"column"}
                gap={2}
                marginTop={2}
              >
                <p>Cet étudiant n'est pas encore approuvé</p>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isLoadinApprove}
                  startIcon={
                    isLoadinApprove ? (
                      <CircularProgress />
                    ) : (
                      <NoEncryptionGmailerrorredIcon />
                    )
                  }
                  onClick={() => onApprove(student._id)}
                >
                  {!isLoadinApprove && "Approve"}
                </Button>
              </Box>
            ) : (
              isSuperAdmin && (
                <Box marginTop={2} display={"flex"} marginBottom={2} gap={2}>
                  <UpdateStudentProfilePopup
                    handleClose={handleClose}
                    student={student}
                    setStudent={setStudent}
                    modalOpen={modalOpen}
                  />
                  <Button
                    onClick={handleOpen}
                    variant="contained"
                    color="primary"
                  >
                    Modifier Le profile
                  </Button>
                </Box>
              )
            )}
          </Box>
          <Box flex={1} display={"flex"} flexDirection={"column"} gap={2}>
            <BasicInfo isSuperAdmin={isSuperAdmin} student={student} />
            <CenterInfo isSuperAdmin={isSuperAdmin} student={student} />
            <PaymentTable
              isSuperAdmin={isSuperAdmin}
              student={student}
              setStudent={setStudent}
            />
          </Box>
        </Box>
      )}
    </LayoutWrraper>
  );
}
