import {
  Box,
  Button,
  Container,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Modal,
  Select,
  Typography,
  MenuItem,
  OutlinedInput,
} from "@mui/material";
import { useEffect, useState } from "react";
import { backendApi } from "../../api";
import toast from "react-hot-toast";
import { getAllCenter } from "../services/center.servies";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const updateStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-35%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 4,
};

export default function AddNewAdmin({
  setAdmins,
  modalOpen,
  handleModalClose,
}) {
  const [info, setInfo] = useState({
    fullname: "",
    role: "admin",
    email: "",
    isActive: true,
    password: "",
    adminCenterId: undefined,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [centers, setCenters] = useState([]);
  const [centersIsLoading, setCentersIsLoading] = useState(false);

  const handleCreateAdmin = async () => {
    try {
      setIsLoading(true);
      const res = await backendApi.post("/admin/create/", info);
      if (res.status != 201) throw res;
      const admins_res = await backendApi.get("/admin/admins/");
      setAdmins(admins_res.data);
      toast.success(res.data.message);
      setIsLoading(false);
      handleModalClose(true);
    } catch (err) {
      toast.error(err.response.data.message || err.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchCenter = async () => {
      setCentersIsLoading(true);
      try {
        const res = await getAllCenter();
        console.log("Trs --> ", res);
        setCenters(res);
        setCentersIsLoading(false);
      } catch (err) {
        toast.error(err || "Faild to fetch all center");
        setCentersIsLoading(false);
      }
    };
    fetchCenter();
  }, []);
  return (
    <Modal
      keepMounted
      open={modalOpen}
      onClose={handleModalClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <Box sx={updateStyle}>
        <h2 className="font-bold mb-6 mt-2">Ajouter Novaux Administrateur</h2>
        <Box>
          <Box marginBottom={3}>
            <TextField
              fullWidth
              label="Le nom complet "
              type="text"
              value={info.fullname || ""}
              onChange={(e) => setInfo({ ...info, fullname: e.target.value })}
            />
          </Box>
          <Box marginBottom={3}>
            <TextField
              fullWidth
              label="Email"
              type="email"
              value={info.email || ""}
              onChange={(e) => setInfo({ ...info, email: e.target.value })}
            />
          </Box>
          <Box marginBottom={3}>
            <TextField
              fullWidth
              label="Mot de pass"
              type="password"
              value={info.password || ""}
              onChange={(e) => setInfo({ ...info, password: e.target.value })}
            />
          </Box>
          <Box
            marginTop={2}
            sx={{ display: "flex", flexDirection: "column", gap: 2 }}
          >
            <label htmlFor="center">
              Sélectionnez le centre que l'administrateur gérera
            </label>
            {centersIsLoading ? (
              <CircularProgress />
            ) : (
              <Select
                id="center"
                variant="outlined"
                label="Sélectionnez le centre que l'administrateur gérera"
                onChange={(e) =>
                  setInfo({ ...info, adminCenterId: e.target.value })
                }
                value={info.adminCenterId || ""}
                input={
                  <OutlinedInput label="Sélectionnez le centre que l'administrateur gérera" />
                }
                MenuProps={MenuProps}
              >
                {centers.map((item, index) => (
                  <MenuItem key={index} value={item?._id}>
                    {item?.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          </Box>
          <Box
            marginTop={2}
            sx={{ display: "flex", flexDirection: "column", gap: 2 }}
          >
            <label htmlFor="role">Rôle De L'utilisateur</label>
            <Select
              id="role"
              variant="outlined"
              label="Rôle De L'utilisateur"
              onChange={(e) => setInfo({ ...info, role: e.target.value })}
              value={info.role || ""}
              fullWidth={true}
              input={<OutlinedInput label="Rôle De L'utilisateur" />}
              MenuProps={MenuProps}
            >
              <MenuItem value={"admin"}>Administrateur Standard</MenuItem>
              <MenuItem value={"super_admin"}>Super Administrateur</MenuItem>
            </Select>
          </Box>
          <Box
            marginTop={2}
            sx={{ display: "flex", flexDirection: "column", gap: 2 }}
          >
            <label htmlFor="status">Statut Du Compte</label>
            <Select
              id="status"
              variant="outlined"
              label="Statut Du Compte"
              onChange={(e) => setInfo({ ...info, isActive: e.target.value })}
              value={info.isActive}
              fullWidth={true}
              input={<OutlinedInput label="Statut Du Compte" />}
              MenuProps={MenuProps}
            >
              <MenuItem value={false}>Désactivé</MenuItem>
              <MenuItem value={true}>Actif</MenuItem>
            </Select>
          </Box>
          <Button
            disabled={isLoading}
            onClick={handleCreateAdmin}
            variant="contained"
            style={{ marginTop: "20px" }}
            fullWidth
          >
            {isLoading ? <CircularProgress /> : "Enregistrer"}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
