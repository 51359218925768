import { Box, TextField, Typography, Select,MenuItem,OutlinedInput, Container, Button } from "@mui/material";
import LayoutWrraper from "../../components/layout/LayoutWrraper";
import { Form } from "react-router-dom";
import { useState } from "react";
import { CreateFormationHandler } from "../../services/formations.services";
import toast from "react-hot-toast";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const CreateFormation = () => {
    const [isLoaded, setIsLoaded] = useState(true);
    const [info, setInfo] = useState({
        name: "",
        description: "",
        duration: 3,
        price: "",
    })
    const onCreation = async () => {
        setIsLoaded(false);
        try {
            const res = await CreateFormationHandler(info);
            if (res) {
                toast.success("Formation ajoutée avec succès");
                setInfo({
                    name: "",
                    description: "",
                    duration: 3,
                    price: "",
                });
                setIsLoaded(true);
            }else {
                throw new Error("Une erreur s'est produite lors de la création de la formation");
            }
        } catch (error) {
            setIsLoaded(true);
            toast.error(error.message);
        }
    }
    return (
        <LayoutWrraper>
            <Container>
                <Typography variant="h4">Ajouter une Nouvelle Formation</Typography>
                <Form>
                    <Box marginTop={2}  sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                        <label htmlFor="title">Titre</label>
                        <TextField 
                            id="title" 
                            variant="outlined" 
                            label="Titre"
                            value={info.title} 
                            onChange={(e) => setInfo({ ...info, name: e.target.value })}
                        />
                    </Box>
                    <Box marginTop={2} sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                        <label htmlFor="description">Description</label>
                        <TextField 
                            id="description" 
                            label="Description"
                            variant="outlined" 
                            value={info.description} 
                            onChange={(e) => setInfo({ ...info, description: e.target.value })} 
                        />
                    </Box>
                    <Box marginTop={2} sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                        <label htmlFor="price">Prix de la formation</label>
                        <TextField 
                            id="price" 
                            label="Prix"
                            variant="outlined" 
                            value={info.price} 
                            onChange={(e) => setInfo({ ...info, price: e.target.value })} 
                        />
                    </Box>
                    <Box marginTop={2} sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                        <label htmlFor="duration">Durée de la formation</label>
                        <Select 
                        id="duration"
                        variant="outlined"
                        label="Durée"
                        onChange={(e) => setInfo({ ...info, duration: e.target.value })}
                        value={info.duration}
                        fullWidth={true}
                        input={<OutlinedInput label="Name" />}
                        MenuProps={MenuProps}
                        >
                        {
                            Array.from({ length: 12 }, (_, i) => i + 1).map((i) => (
                                <MenuItem key={i} value={i}>{i} mois</MenuItem>
                            ))
                        }
                        </Select>
                    </Box>
                    <Box marginTop={2}>
                        <Button 
                            onClick={isLoaded &&  onCreation}
                            variant="contained" 
                            color="primary">
                                {isLoaded ? "Ajouter" : "Chargement..."}
                            </Button>
                    </Box>
                </Form>
            </Container>
        </LayoutWrraper>
    );
}