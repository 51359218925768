import React, { useState, useEffect, useRef } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useAdmin, useAuth, useSuperAdmin } from './services/auth.services';
import { Box, CircularProgress } from '@mui/material';
import imaLogo from "./assets/images/logo.png"
const ImaLoading = () => {
  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      flexDirection={"column"}
      height={"100vh"}
      gap={"20px"}
    >
      <img
        style={{
          width : "100px",
          height : "100px"
        }}
        src={imaLogo}
        alt='ima logo'
      />
      <Box
        display={"flex"}
        alignItems={"center"}
        gap={"10px"}
      >
        <CircularProgress
          size={20}
        />
        <p>Cheking user ...</p>
      </Box>
    </Box>
  );
}

export const  AuthLayout = () => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated === null) {
    console.log("heree")
    return  <ImaLoading />
  }

  if (isAuthenticated) {
    console.log("isAuthenticated ---> ", isAuthenticated);
    return <Outlet />; // Wrap Outlet in JSX
  }

  return <Navigate to="/login" />; // Wrap Navigate in JSX
};

export const AdminPermissionLayout = () => {
  const {isAdmin} = useAdmin();
  if (isAdmin === null)
    return  <ImaLoading />
  if (isAdmin) {
    console.log("admin loaded")
    return <Outlet />;
  }

  return <Navigate to="/" />;
}


export const SuperAdminPermissionLayout = () => {
  const {isSuperAdmin} = useSuperAdmin();
  
  if (isSuperAdmin === null)
    return  <ImaLoading />
  if (isSuperAdmin)
    return <Outlet />;
  return <Navigate to="/admin" />;
}