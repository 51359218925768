import { backendApi } from "../api";

export const fetchFormations = async () => {
    try {
        const response = await backendApi.get("/formations/all/");
        if (response.status === 200) return response.data;
        else return undefined;
    } catch (error) {
        console.log(error);
        return undefined;
    }
}