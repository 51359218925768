import { Box, Container,Alert } from "@mui/material";
import Header from "../../Components/Header";
import CompleteProfile from "../../Components/CompleteProfile";
import { useEffect } from "react";
import { getUser, isAuth } from "../../services/auth.services";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import SProfile from "../../Components/profile/SProfile";
import { useState } from "react";

export default function StudentProfile() {
  const navigate = useNavigate();
  const [student, setStudent] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const isUserAuth = async () => {
      const isAuthentificated = await isAuth();
      if (!isAuthentificated) {
        toast.error("Vous devez vous connecter d'abord");
        navigate("/register");
      }
    }
    isUserAuth();
    const getStudentData = async () => {
      try {
        const userInfo = await getUser();
        setIsLoaded(true);
        setStudent(userInfo);
      } catch (error) {
        setIsLoaded(true);
        toast.error("Je n'ai pas pu obtenir les informations de l'utilisateur.");
      }
    }
    getStudentData();
  }, [])
  return (
    <Box>
      <Header />
      <Container maxWidth="xl">
        {isLoaded && (!student?.profile && <CompleteProfile /> || <SProfile student={student} />) 
        || <p style={{
          textAlign: 'center',
          marginTop: '20px',
          fontSize: '1.5rem'
        }}>Loading...</p>}
      </Container>
    </Box>
  );
}