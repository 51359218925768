import { Box, Button } from "@mui/material";
import dateFormat, { masks } from "dateformat";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper'
import { imageApiUri } from "../../api";
import NoEncryptionGmailerrorredIcon from '@mui/icons-material/NoEncryptionGmailerrorred';

const monthStyles = ["Premier mois", "Deuxième mois", "Troisième mois", "Quatrième mois", "Cinquième mois", "Sixième mois", "Septième mois", "Huitième mois", "Neuvième mois", "Dixième mois", "Onzième mois", "Douzième mois"];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const leftItemStyles  = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'white',
  borderRadius: '10px',
  boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.2)',
  padding: '10px',
  flex: '0.5',
  maxHeight:'500px'
}

const rightItemStyles = {
  backgroundColor: 'white',
  borderRadius: '10px',
  boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.2)',
  padding: '30px',
  height: 'auto'
}

const ProfileImage = ({student}) => {
  return (
    <img 
      src={student?.profile?.profileImage ?
        `${imageApiUri}${student?.profile?.profileImage}` : 'https://via.placeholder.com/150'} 
      alt={student?.fullname} 
      style={{
        width: '100px', 
        height: '100px', 
        borderRadius: '50%'
      }}
    />
  );
}

const pStyle = {
  color: 'grey',
  fontSize: '1rem',
  marginTop: '10px',
  marginBottom: '5px'
}

const BasicInfo = ({student}) => {
  return (
    <Box style={rightItemStyles}>
      <h2>Basic Information</h2>
      <Box
        // grid
        display={{ xs: 'block', sm: 'grid' }} 
        gridTemplateColumns={
          { xs: '1fr', sm: '1fr 1fr 1fr' }
        }
        gap={2}
      >
        <Box >
          <p style={pStyle}>Age</p>
          <p> {new Date().getFullYear() - new Date(student?.profile?.birthday).getFullYear()} years old</p>
        </Box>
        <Box >
          <p style={pStyle}>Date de naissance</p>
          <p> {dateFormat(student?.profile?.birthday, masks['fullDate'])}</p>
        </Box>
        <Box >
          <p style={pStyle}>Téléphone</p>
          <p>
            {(student?.profile?.phone.startsWith('+212') || student?.profile?.phone.startsWith('212')) ? student?.profile?.phone : '+212 ' + student?.profile?.phone.slice(1)}
          </p>
        </Box>
        <Box >
          <p style={pStyle}>Email</p>
          <p>
            {student?.email}
          </p>
        </Box>
        <Box >
          <p style={pStyle}>City</p>
          <p>
            {student?.profile?.city}
          </p>
        </Box>
        <Box >
          <p style={pStyle}>Cin</p>
          <p>
            {student?.profile?.cin}
          </p>
        </Box>
        <Box >
          <p style={pStyle}>Niveau d'étude</p>
          <p>
            {student?.profile?.levelStudy}
          </p>
        </Box>
      </Box>
    </Box>
  );
}

const CenterInfo = ({student}) => {
  return (
    <Box style={rightItemStyles}>
      <h2> Votre Centre </h2>
      <Box
        // grid
        display={{ xs: 'block', sm: 'grid' }} 
        gridTemplateColumns={
          { xs: '1fr', sm: '1fr 1fr 1fr' }
        }
        gap={2}
        >
        <Box >
          <p style={pStyle}>Nome de centre</p>
          {/* {console.log(student?.profile?.center)} */}
          <p> {student?.profile?.center?.name?.toUpperCase()}</p>
        </Box>
        <Box >
          <p style={pStyle}>Formation</p>
          <p> {student?.profile?.formation?.name}</p>
        </Box>
        <Box >
          <p style={pStyle}>La date de registration</p>
          <p> {dateFormat(student?.profile?.createdAt, masks['fullDate'])} </p>
        </Box>
        <Box >
          {/* calculate geaused school end deadline 3 months */}
          <p style={pStyle}> Fin de formation (appréciation)</p>
          <p> {dateFormat(new Date(student?.profile?.createdAt).setMonth(new Date(student?.profile?.createdAt).getMonth() + 3), masks['fullDate'])} </p>
        </Box>
      </Box>
    </Box>

  );
}

const PaymentTable = ({student}) => {
  return (
    <Box style={rightItemStyles}>
      <h2> Votre Paiement </h2>
      <TableContainer component={Paper} style={{
        marginTop: '20px'
      
      }}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
              <StyledTableCell>Payment ID</StyledTableCell>
              <StyledTableCell align="left">Mois</StyledTableCell>
              <StyledTableCell align="left">Amount</StyledTableCell>
              <StyledTableCell align="left">Status</StyledTableCell>
              <StyledTableCell align="left">Date</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!student?.payments  ? 
            <StyledTableRow >
              <StyledTableCell style={{
              padding: '20px',
              color: 'grey',
              backgroundColor: 'white'
            }}>
              Vous n'avez pas encore effectué de paiement
              </StyledTableCell>
            </StyledTableRow>
            :
            student?.payments?.map((row) => (
              <StyledTableRow key={row.name}>
                <StyledTableCell component="th" scope="row">
                  {row._id}
                </StyledTableCell>
                <StyledTableCell align="left">{
                  monthStyles[row.month - 1]
                }</StyledTableCell>
                <StyledTableCell align="left">{row.amount} DH</StyledTableCell>
                <StyledTableCell align="left">{row.status}</StyledTableCell>
                <StyledTableCell align="left">{
                  dateFormat(row.createdAt, masks['fullDate'])
                }</StyledTableCell>
              </StyledTableRow>
            ))
          }
        </TableBody>
      </Table>
    </TableContainer>
    </Box>
  );
}

export default function  SProfile ({student}) {
  return (
    <Box 
      display={{ xs: 'flex', sm: 'flex' }}
      flexDirection={{ xs: 'column', sm: 'row' }}
      height={'100%'}
      gap={2}
      marginTop={2}
    >
      <Box style={leftItemStyles}>
        <ProfileImage student={student}/>
        <p style={{
          marginTop : '20px',
        }}>{student?.fullname?.toUpperCase()}</p>
        <p 
          style={{
            marginTop: '20px',
            color: 'grey',
            fontSize: '0.8rem',
            textAlign: 'center',
          }}
        >
          {student?.profile?.description}
        </p>
        <Box
            marginTop={2} 
            display={'flex'} 
            marginBottom={2}
           gap={2}>
          <Button 
            disabled={student?.profile?.isApproved === false ? true : false}
            variant="contained" 
            color="primary"> 
            Modifier votre profile
          </Button>
          <Button variant="contained" color="warning"> Contacter l'admin</Button>
        </Box>
      </Box>
        {student?.profile?.isApproved === false ? (
          <Box flex={1} display={'flex'} flexDirection={'column'} gap={2}>
            <p style={{
              color: 'grey',
              fontSize: '1.5rem',
              textAlign: 'center',
              marginTop: '20px'
            }}>
              <NoEncryptionGmailerrorredIcon 
                color="error"
                style={{fontSize: '5rem'}} 
              />
              <p>
              {
                new Date().getHours() > 18 ? "Bonsoir" : new Date().getHours() > 12 ? "Bon après-midi" : "Bonjour"
              },
              Votre compte est en attente de validation, veuillez patienter, Merci.
              </p>
              <p>
                Si vous avez des questions, veuillez contacter l'administrateur.
              </p>
            </p>
          </Box>
        ) : (
          <Box flex={1} display={'flex'} flexDirection={'column'} gap={2}>
            <BasicInfo student={student}/>
            <CenterInfo student={student}/>
            <PaymentTable student={student}/>
          </Box>
        )}
    </Box>
  );
}