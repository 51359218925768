import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { Form } from "react-router-dom";
import { monthsTextStyle } from "./Profile";
import dateFormat, { masks } from "dateformat";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const PaymentPopup = ({
  student,
  info,
  setInfo,
  onCreatePayment,
  open,
  setOpen,
  isLoading,
}) => {
  const handleClose = () => {
    setOpen(false);
  };

  const handleSetDateAuto = () => {
    const date = new Date();
    setInfo({
      ...info,
      paymentDate: dateFormat(date, masks["isoDate"]),
    });
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{`Créer un paiement pour  ${student?.fullname.toUpperCase()}`}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          <Form
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            <Box display={"flex"} flexDirection={"column"} gap={1}>
              <label htmlFor="center">
                L'institut est Formation que vous avez payé
              </label>
              <TextField
                id="center"
                variant="outlined"
                placeholder="Center"
                disabled
                value={`${student?.profile?.center?.name.toUpperCase()} - ${student?.profile?.formation?.name.toUpperCase()}`}
              />
            </Box>
            <Box display={"flex"} flexDirection={"column"} gap={1}>
              <label htmlFor="amount">Entrez le montant à payer</label>
              <TextField
                id="amount"
                type="number"
                variant="outlined"
                value={info.amount}
                onChange={(e) => setInfo({ ...info, amount: e.target.value })}
                placeholder="Montant"
              />
            </Box>
            <Box display={"flex"} flexDirection={"column"} gap={1}>
              {/* month */}
              <label htmlFor="paymentId">Entrez quelque mois</label>
              <Select
                labelId="demo-multiple-name-label"
                value={info.month || ""}
                onChange={(e) => setInfo({ ...info, month: e.target.value })}
                fullWidth
                variant="outlined"
                id="paymentId"
                input={<OutlinedInput label="Name" />}
                MenuProps={MenuProps}
              >
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((month) => (
                  <MenuItem key={month} value={month}>
                    {month} - {monthsTextStyle[month - 1]}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box display={"flex"} flexDirection={"column"} gap={1}>
              {/* month */}
              <label htmlFor="status">Entrez le statut</label>
              <Select
                labelId="demo-multiple-name-label"
                value={info.status}
                onChange={(e) => setInfo({ ...info, status: e.target.value })}
                fullWidth
                variant="outlined"
                id="status"
                input={<OutlinedInput label="Name" />}
                MenuProps={MenuProps}
              >
                  <MenuItem value="pending">
                    En attente
                  </MenuItem>
                  <MenuItem value="paid">
                    Payé
                  </MenuItem>
              </Select>
            </Box>
            <Box display={"flex"} flexDirection={"column"} gap={1}>
              <label htmlFor="paymentDate">Entrez la date de paiement</label>
              <Box display={"flex"} alingItems={"center"} width={"100%"}>
                <TextField
                  id="paymentDate"
                  type="date"
                  variant="outlined"
                  placeholder="Date de paiement"
                  value={info.paymentDate}
                  onChange={(e) =>
                    setInfo({ ...info, paymentDate: e.target.value })
                  }
                  flex={1}
                  fullWidth
                />
                <Button
                  variant="contained"
                  color="primary"
                  flex={0.7}
                  style={{
                    marginLeft: "10px",
                  }}
                  onClick={handleSetDateAuto}
                >
                  AUTO
                </Button>
              </Box>
            </Box>
            <Box>
                {isLoading ? 
                  <Button
                    variant="contained"
                    disabled
                    fullWidth
                  >
                    <CircularProgress />
                  </Button> 
                    : <Button
                        variant="contained"
                        color="success"
                        fullWidth
                        onClick={onCreatePayment}
                      >
                        Effectuer le paiement
                      </Button>}
            </Box>
          </Form>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={isLoading} onClick={handleClose}>Annuler</Button>
      </DialogActions>
    </Dialog>
  );
};
export const UpdatePaymentPopup = ({
  student,
  info,
  setInfo,
  onCreatePayment,
  open,
  setOpen,
  isLoading
}) => {

  const handleClose = () => {
    setOpen(false);
  };

  const handleSetDateAuto = () => {
    const date = new Date();
    setInfo({
      ...info,
      paymentDate: dateFormat(date, masks["isoDate"]),
      
    });
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{`Créer un paiement pour  ${student?.fullname.toUpperCase()}`}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          <Form
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            <Box display={"flex"} flexDirection={"column"} gap={1}>
              <label htmlFor="center">
                L'institut est Formation que vous avez payé
              </label>
              <TextField
                id="center"
                variant="outlined"
                placeholder="Center"
                disabled
                value={`${student?.profile?.center?.name} - ${student?.profile?.formation?.name}`}
              />
            </Box>
            <Box display={"flex"} flexDirection={"column"} gap={1}>
              <label htmlFor="amount">Entrez le montant à payer</label>
              <TextField
                id="amount"
                type="number"
                variant="outlined"
                value={info.amount}
                onChange={(e) => setInfo({ ...info, amount: e.target.value })}
                placeholder="Montant"
              />
            </Box>
            <Box display={"flex"} flexDirection={"column"} gap={1}>
              {/* month */}
              <label htmlFor="paymentId">Entrez quelque mois</label>
              <Select
                labelId="demo-multiple-name-label"
                value={info.month || ""}
                onChange={(e) => setInfo({ ...info, month: e.target.value })}
                fullWidth
                variant="outlined"
                id="paymentId"
                input={<OutlinedInput label="Name" />}
                MenuProps={MenuProps}
              >
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((month) => (
                  <MenuItem key={month} value={month}>
                    {month} - {monthsTextStyle[month - 1]}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box display={"flex"} flexDirection={"column"} gap={1}>
              {/* month */}
              <label htmlFor="status">Entrez le statut</label>
              <Select
                labelId="demo-multiple-name-label"
                value={info.status}
                onChange={(e) => setInfo({ ...info, status: e.target.value })}
                fullWidth
                variant="outlined"
                id="status"
                input={<OutlinedInput label="Name" />}
                MenuProps={MenuProps}
              >
                  <MenuItem value="pending">
                    En attente
                  </MenuItem>
                  <MenuItem value="paid">
                    Payé
                  </MenuItem>
              </Select>
            </Box>
            <Box display={"flex"} flexDirection={"column"} gap={1}>
              <label htmlFor="paymentDate">Entrez la date de paiement</label>
              <Box display={"flex"} alingItems={"center"} width={"100%"}>
                <TextField
                  id="paymentDate"
                  type="date"
                  variant="outlined"
                  placeholder="Date de paiement"
                  value={info.paymentDate}
                  onChange={(e) =>
                    setInfo({ ...info, paymentDate: e.target.value })
                  }
                  flex={1}
                  fullWidth
                />
                <Button
                  variant="contained"
                  color="primary"
                  flex={0.7}
                  style={{
                    marginLeft: "10px",
                  }}
                  onClick={handleSetDateAuto}
                >
                  AUTO
                </Button>
              </Box>
            </Box>
            <Box>{
              isLoading ?  
              <Button
                variant="contained"
                disabled
                fullWidth
              >
                <CircularProgress />
              </Button> 
              : <Button
                  variant="contained"
                  color="success"
                  fullWidth
                  onClick={onCreatePayment}
                >
                  Effectuer le paiement
                </Button>
            }
            </Box>
          </Form>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Annuler</Button>
      </DialogActions>
    </Dialog>
  );
};
