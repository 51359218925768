import { Box, Button } from '@mui/material';
import topAbout from '../../assets/images/topabout.png'
import   '../../assets/style/about.css'
import imaVideo from '../../assets/video/ima_video.mp4'
import imaCenter from '../../assets/images/ima_center.png'
import PlayCircleIcon from '@mui/icons-material/PlayCircle';

export default function About() {
    const _onReady = (e) => {
        e.target.pauseVideo();
    }

    const playVideo = () => {
        const Parrentvideo = document.getElementById("about_video")
        const video = document.getElementById("veido_about");
        Parrentvideo.style.display = "flex"
        video.play();
    }
    const hideVideo = (e) => {
        const parrentVideo = document.getElementById("about_video")
        const video = document.getElementById("veido_about");
        if (!e.target.classList.contains("about_ima_video")) {
            parrentVideo.style.display = "none"
            video.pause();
        }
    }
    return (
        <Box className="about">
            <Box className="about_data">
                <Box style={{
                    flex : "1"
                }}>
                    <h2>A PROPOS DE NOUS</h2>
                    <p>
                        INSTITUT IMA STF de L'education et de formation professionnelle 
                        de renommée est fondé en 2016 par des lauréats de l’université. 
                        L’institut IMA de formation professionnelle de renommée 
                        est fondé en 2016 par des lauréats de l’université Ibn Zohr.
                    </p>
                    <p className='paragraph_about'>
                        L’institut s’est vu confier, la responsabilité d’assurer des formations de qualité aux jeunes étudiants de la ville d’Agadir et les préparer à la fois pour réussir leurs examens et à la fois pour assurer leurs avenirs et booster leurs carrières académiques et professionnelles.
                        Pour accomplir ces missions-là, l’institut IMA met à la disposition de ses stagiaires et ses étudiants des équipes pédagogiques qualifiées et assure un environnent d’apprentissage favorable répondant à leurs attentes individuelles et collectives.
                    </p>
                </Box>
                <Box onClick={playVideo} className="left_items_about">
                    <img className='play_bg' src={imaCenter} />
                    <div className='play_bg _bg_about_'>
                        <PlayCircleIcon size="100px" fontSize='50px' className='play_icon_about' />
                    </div>
                </Box>
            </Box>
            <Box onClick={hideVideo} id="about_video" className="video_section_about">
                <video id="veido_about" className='about_ima_video' >
                    <source src={imaVideo} type="video/mp4"/>
                </video>
            </Box>
        </Box>
    );
}