import {
  Box,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { imageApiUri } from "../../api";
import dateFormat from "dateformat";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import { monthsTextStyle } from "../pages/manage/students/Profile";
import FaxIcon from "@mui/icons-material/Fax";


export const updateStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-35%, -50%)",
  width: "50%",
  minWidth : "966px",
  maxWidth : "966px",
  bgcolor: "background.paper",
  borderRadius : "10px",
  p: 4,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const status = {
  paid: "Payé",
  pending: "En attente",
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function Invoice({
  student,
  handleModalClose,
  modalOpen,
  payment,
  invoiceIndex,
}) {
  console.log("studet- >>>>>> ", student);
  return (
    <Modal
      open={modalOpen}
      onClose={handleModalClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
      hideBackdrop
      style={{
        position : "absolute",
        left : "-999999px",
        top : "-9999999px",
        background : "none"
      }}
    >
        <Box   
          sx={updateStyle} id="invoice">
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Box
              display={"flex"}
              alignItems={"center"}
              flexDirection={"column"}
              textAlign={"center"}
            >
              <img
                style={{
                  width: "80px",
                  height: "80px",
                }}
                src={imageApiUri + student?.profile?.center?.logo}
                alt="logo de center"
              />
              <Box marginTop={1}>
                <h1
                  style={{
                    fontSize: "15px",
                    fontWeight: "bold",
                  }}
                >
                  INSTITUT{" "}
                  {String(student?.profile?.center?.name).toUpperCase()}
                </h1>
                <p
                  style={{
                    fontSize: "12px",
                    fontWeight: "lighter",
                  }}
                >
                  institut pour des formations
                </p>
              </Box>
            </Box>
            <Box>
              <h1
                style={{
                  lineHeight: "35px",
                  fontWeight: "bold",
                  fontSize: "40px",
                  marginBottom : "5px"
                }}
              >
                FACTURE
              </h1>
              <p className="font-bold text-right text-slate-500">
                #{invoiceIndex + 1}
                {String(payment?._id).substring(0, 5)}
              </p>
            </Box>
          </Box>
          <hr
            style={{
              marginTop: "30px",
              marginBottom: "30px",
              opacity: 0.5,
            }}
          />
          <Box
            display={"flex"}
            alignItems={"flex-start"}
            justifyContent={"space-between"}
          >
            <Box>
              <div className="font-light leading-6">
                <p style={{ display: "flex", alignItems: "baseline" }}>
                  <span style={{ minWidth: "150px" }}>ID du Compte</span>
                  <span className="text-slate-500">
                    {String(student?._id).substring(0, 10)}
                  </span>
                </p>
                <p style={{ display: "flex", alignItems: "baseline" }}>
                  <span style={{ minWidth: "150px" }}>La date de Facture</span>
                  <span className="text-slate-500">
                    {dateFormat(new Date(), "dd-mm-yyyy, HH:MM:ss")}
                  </span>
                </p>
                <p style={{ display: "flex", alignItems: "baseline" }}>
                  <span style={{ minWidth: "150px" }}>Numero de Facture</span>
                  <span className="text-slate-500">
                    #{invoiceIndex + 1} {String(payment?._id).substring(0, 10)}
                  </span>
                </p>
              </div>
            </Box>
            <Box>
              <div className="font-light leading-6">
                <p style={{ display: "flex", alignItems: "baseline" }}>
                  <span style={{ minWidth: "150px" }}>Nom de l'étudiant</span>
                  <span className="font-semibold">{student?.fullname}</span>
                </p>
                <p style={{ display: "flex", alignItems: "baseline" }}>
                  <span style={{ minWidth: "150px" }}>Formation</span>
                  <span className="font-semibold">
                    {student?.profile?.formation?.name}
                  </span>
                </p>
              </div>
            </Box>
          </Box>
          <hr
            style={{
              marginTop: "30px",
              marginBottom: "30px",
              opacity: 0.5,
            }}
          />
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>ID</StyledTableCell>
                  <StyledTableCell align="left">STATUS</StyledTableCell>
                  <StyledTableCell align="left">Mois</StyledTableCell>
                  <StyledTableCell align="left">Amount</StyledTableCell>
                  <StyledTableCell align="left">
                    Date de paiement
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell align="left">
                    #{invoiceIndex}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {status[payment?.status]}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {monthsTextStyle[payment?.month - 1]}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {payment?.amount} Dirhams
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {dateFormat(payment?.createdAt, "dd-mm-yyyy, HH:MM")}
                  </StyledTableCell>
                </StyledTableRow>
                <TableRow>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell colSpan={1}>
                    <p className="font-bold">TOTAL</p>
                  </TableCell>
                  <TableCell align="left">
                    <p className="font-bold">{payment?.amount} Dirhams</p>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <hr
            style={{
              marginTop: "30px",
              marginBottom: "30px",
              opacity: 0.5,
            }}
          />
          <Box
            display={"flex"}
            alignItems={"flex-start"}
            justifyContent={"space-between"}
          >
            <Box>
              <p color="red">"Veuillez noter que les remboursements ne sont pas possibles une fois le paiement effectué.</p>
              <p>Nous vous remercions pour votre confiance.</p>
            </Box>
            <Box marginBottom={10}>
              <p>Pour toute question, contactez-nous à</p>
              <p className="mt-3 gap-3 flex items-center font-light text-sm">
                <LocationOnIcon fontSize="12px" />{" "}
                <p>{student?.profile?.center?.address}</p>{" "}
              </p>
              <p className="flex gap-3 items-center font-light text-sm">
                <PhoneEnabledIcon fontSize="12px" />{" "}
                <p>{student?.profile?.center?.whatssap}</p>{" "}
              </p>
              <p className="flex gap-3 items-center font-light text-sm">
                <FaxIcon fontSize="12px" />
                <p>{student?.profile?.center?.fix}</p>
              </p>
            </Box>
          </Box>
        </Box>
    </Modal>
  );
}
