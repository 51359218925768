import {
  Box,
  Modal,
} from "@mui/material";

const updateStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-35%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 4,
};

export const StatisticsPayment = ({
  handleModalClose,
  data,
  modalOpen,
  type,
}) => {
  return (
    <Modal
      keepMounted
      open={modalOpen}
      onClose={handleModalClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <Box sx={updateStyle}>
        <h2>{type === "old" ? "Liste de tous les paiements des anciens étudiants" : "Liste de tous les paiements de nouveaux étudiants"} </h2>
        <Box 
            display={"flex"}
            flexDirection={"column"}
            gap={0.5}
            marginTop={3}
        >
          {data.map((pay, index) => (
            <Box
              key={index}
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              bgcolor={"#f9f9f9"}
              padding={"10px"}
              borderRadius={"5px"}
              minWidth={"500px"}
            >
              <Box display={"flex"} alignItems={"center"}>
                <h3 className="font-light mr-3 text-gray-800">
                {pay?.user?.fullname}
                </h3>
                <p className="text-gray-500">
                {new Date(pay.createdAt).toLocaleDateString()}
                </p>
              </Box>
              <Box>
                <p className="text-green-500 font-bold">{pay.amount} DH</p>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Modal>
  );
};
