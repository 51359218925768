import React from "react";
import Header from "../Components/Header";
import { Box } from "@mui/system";
import Banner from "../Components/home/Banner";
import Missions from "../Components/home/Missions";
import About from "../Components/home/About";
import Contact from "../Components/home/Contact";
import Footer from "../Components/home/Footer";


function Home() {
  // document.body.addEventListener("scroll", () => {
  //   const currentScroll = window.scrollY;  // Get current scroll position
  //   const viewportHeight = window.innerHeight;  // Get the height of the viewport
  
  //   if (currentScroll < viewportHeight) {
  //     // Scroll to the top of the next viewport
  //     window.scrollTo({
  //       top: viewportHeight,
  //       behavior: "smooth" // Smooth scrolling
  //     });
  //   }
  // });
  return (
    <Box>
      <Header />
      <Banner />
      <Missions />
      <About />
      <Contact />
      <Footer />
    </Box>
  );
}

export default Home;