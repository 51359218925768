import axios from "axios";
import Cookies from "js-cookie";
export const apiUri = "https://api.institute-ima.com";
export const imageApiUri = ""; //tmp it need to remove later

export let backendApi = axios.create({
  baseURL: apiUri,
  headers: {
    "Content-Type": "application/json",
    "Accept": "application/json",
    "Access-Control-Allow-Origin": "*",
  }
})

backendApi.interceptors.request.use(config => {
  const token = Cookies.get("access_token");
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`
  }
  return config;
});