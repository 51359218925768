import { Container,Box, TextField, Select,MenuItem, Button, CircularProgress } from "@mui/material";
import LayoutWrraper from "../../../components/layout/LayoutWrraper";
import { useEffect, useState } from "react";
import { backendApi } from "../../../../api";
import toast from "react-hot-toast";
import { fetchFormations } from "../../../../services/fetchFormations.services";
import { useNavigate } from "react-router-dom";
import { uploadProfileImage } from "../../../../services/auth.services";

import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export default function CreateStudent() {
  const [data, setData] = useState({
    fullname : "",
    email : "",
    password : "null",
    profileImage : undefined,
    description : "created by admin",
    phone : 0,
    cin : "",
    birthday : "",
    formation : "",
    center : "",
    city : "",
  })
  const [formations, setFormation] = useState([]);
  const [centers, setCenters] = useState([]);
  const navigate = useNavigate();
  const [createLoadin, setCreateLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchFormation  = async () => {
      setLoading(true);
      const data = await fetchFormations();
      if (data) {
        setFormation(data);
        setLoading(false);
      }
      else {
        toast.error("faild to fetch formations");
        setLoading(false);
      }
    }
    fetchFormation();
    const fetchCenters = async () => {
      try {
        setLoading(true);
        const centers =  await backendApi.get("/center/all");
        setCenters(centers.data.centers);
        setLoading(false);
      } catch(err) {
        setLoading(false);
        toast.error(err.message)
      }
    }
    fetchCenters();
  }, [])

  const handleCreateStudent = async () => {
    try {
      setCreateLoading(true)
      if (data.profileImage) {
        const imageData = await uploadProfileImage(data.profileImage)
        if (imageData)
          data["profileImage"] = imageData.filename;
        else {
          toast.error("Faild to store image profile");
          setCreateLoading(false);
          return;
        }
      }
      const res = await backendApi.post("/student/create", data);
      if (res.status === 201) {
        toast.success("Etudient Ajouter to databass successfuly");
        setCreateLoading(false);
        navigate(`/admin/students/profile/${res.data.user._id}`)
      }
    } catch (err) {
      setCreateLoading(false);
      toast.error("Faild to add new student")
    }
  }
  return (
    <LayoutWrraper >
      <Container>
        <h1 className="text-lg text-1xl font-semibomb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl dark:text-black">Ajouter un nouvel étudiant</h1>
        {loading ? <CircularProgress /> : (
                  <Box mt={5}>
                  <Box>
                    <p > Slectionner une image de profile</p>
                    <input
                      type="file"
                      variant="outlined"
                      className="input-profile"
                      onChange={(e) => setData({ ...data, profileImage: e.target.files[0] })}
                    />
                    <Box
                      className="profile-image"
                    >
                      <img
                        src={data?.profileImage instanceof Blob ? URL.createObjectURL(data.profileImage) : data?.profileImage || "https://icons.veryicon.com/png/o/business/back-stage-management/upload-pictures-1.png"}
                        alt="profile"
                        style={{ width: "100px", height: "100px" }}
                      />
                    </Box>
                  </Box>
                  <Box mt={2}>
                    <TextField 
                      type="text"
                      value={data.fullname}
                      label="Entrez le nom complet"
                      onChange={(e) => setData({...data, fullname : e.target.value})}
                      fullWidth
                    />
                  </Box>
                  <Box mt={2}>
                    <TextField 
                      type="tel"
                      value={data.phone}
                      label="Telephon"
                      onChange={(e) => setData({...data, phone : e.target.value})}
                      fullWidth
                    />
                  </Box>
                  <Box mt={2}>
                    <TextField 
                      type="text"
                      value={data.cin}
                      label="Cart National"
                      onChange={(e) => setData({...data, cin : e.target.value})}
                      fullWidth
                    />
                  </Box>
                  <Box mt={2}>
                    <TextField 
                      type="text"
                      value={data.city}
                      label="Ville"
                      onChange={(e) => setData({...data, city : e.target.value})}
                      fullWidth
                    />
                  </Box>
                  <Box mt={2}>
                    <label>Select Formation</label>
                    <Select
                        fullWidth
                        labelId="select-formation"
                        value={data.formation || ""}
                        label="Select Formation"
                        onChange={(e) => setData({...data, formation : e.target.value})}
                    >
                      {formations.map((formation, index) => (
                        <MenuItem 
                          key={index}
                          value={formation._id}>
                            {formation.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                  <Box mt={2}>
                    <label>Select Center</label>
                    <Select
                        fullWidth
                        labelId="select-center"
                        value={data.center || ""}
                        label="Select Center"
                        onChange={(e) => setData({...data, center : e.target.value})}
                    >
                      {centers.map((center, index) => (
                        <MenuItem 
                          key={index}
                          value={center._id}>
                            {center.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                  <Box mt={2}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer
                      components={[
                        'DatePicker',
                        'MobileDatePicker',
                        'DesktopDatePicker',
                        'StaticDatePicker',
                      ]}
                    >
                      <DemoItem label="Date de naissance">
                        <DatePicker 
                          selected={data.birthday || new Date()} 
                          onChange={(e) => setData({...data, birthday : e})}
                        />
                      </DemoItem>
                    </DemoContainer>
                  </LocalizationProvider>
                  </Box>
                  <Button 
                    style={{
                      marginTop : "15px"
                    }}
                    onClick={handleCreateStudent}
                    disabled={createLoadin}
                    fullWidth
                    variant="contained" >
                    {createLoadin ? <CircularProgress /> : "Ajouter"}
                  </Button>
                </Box>
        )}
      </Container>
    </LayoutWrraper>
  );
} 