import { Box,TextField,Button } from "@mui/material";
import { useState,useEffect } from "react";
import Header from "../Components/Header";
import { Form, Link } from "react-router-dom";
import { authUser, isAuth } from "../services/auth.services";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useTheme } from "@emotion/react";

export default function Login() {
  const navigate = useNavigate();
  const [data, setData] = useState({
    email: "",
    password: "",
  });
  const [isLoaded, setIsLoaded] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      const isAuthRes = await isAuth();
      if (isAuthRes) {
        toast.success("Vous êtes déjà connecté !");
        navigate("/");
      }
    }
    checkAuth();
  }, [])

  const handleSubmit = async (e) => {
    setIsLoaded(false);
    e.preventDefault();
    try {
      const response = await authUser(data);
      toast.success("Connexion réussie !");
      setIsLoaded(true);
      navigate("/");
    } catch (error) {
      if (error.response) {
        setIsLoaded(true);
        toast.error(error.response.data.message);
      }
      else {
        setIsLoaded(true);
        toast.error("Une erreur s'est produite, veuillez réessayer.");
      }
    }
  }

  return (
    <Box>
      <Header />
      <Box 
        display={"flex"}
        justifyContent={"center"}
        alignItems={"flex-start"}
        marginTop={"10vh"}
        marginLeft={"30%"}
        marginRight={"30%"}
        gap={2}
        className="login_register"
      >
          <Box 
            flex={1}
            marginTop={"2vh"}
            display={"flex"}
            flexDirection={"column"}
            gap={2}
          >
            <h1 className="text-5xl">
             Heureux de te revoir !
            </h1>
            <p className="ms-2 font-semibold text-gray-500 dark:text-gray-400">
              Tu n'as pas encore de compte ? 
              <Link
                className="text-blue-500 dark:text-blue-400" 
                to="/register"> Inscris-toi</Link> !
            </p>
          </Box>
          <Form 
            style={{flex: 1}}
          >
            {/* full name, email, password, repeat password */}
            <Box>
              <TextField
                label="Email"
                variant="outlined"
                fullWidth
                type="email"
                value={data.email}
                onChange={(e) => setData({ ...data, email: e.target.value })}
                margin="normal"
                required
              />
            </Box>
            <Box>
              <TextField
                label="Mot de passe"
                variant="outlined"
                fullWidth
                type="password"
                value={data.password}
                onChange={(e) => setData({ ...data, password: e.target.value })}
                margin="normal"
                required
              />
            </Box>
            <Box>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleSubmit}
              >
                {!isLoaded ? "Connexion..." : "Se connecter"}
              </Button>
            </Box>
          </Form>
      </Box>
    </Box>
  );
}