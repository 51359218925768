import { Box } from "@mui/material";
import * as React from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PersonalInformation from "./profile/PersonalInformation";
import CenterInformation from "./profile/CenterInformation";
import AboutStudent from "./profile/AboutStudent";
import {createProfile} from "../services/auth.services"
import toast from 'react-hot-toast';
import { useNavigate } from "react-router-dom";

//this steps should be writen with french so it chold be about the student information and with there schole and formstion hi is shouse
const steps = ['Votre information', 'Votre formation', 'A Propos de toi'];

export default function CompleteProfile() {
  const navigate = useNavigate();
  const [info, setInfo] = React.useState({
    phone: "",
    cin: "",
    city: "",
    birthday: "",
    center: "",
    levelStudy: "",
    description: "",
    profileImage: {},
    formation: ""
  })
  const stepsComponents = [
    <PersonalInformation info={info} setInfo={setInfo} />,
    <CenterInformation info={info} setInfo={setInfo} />,
    <AboutStudent info={info} setInfo={setInfo} />
  ]
  
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepOptional = (step) => {
    return false;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      handleFinish();
    }else {
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }
  
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleFinish = async () => {
    await createProfile(info).then((res) => {
      toast.success("Profile Completed successfully")
      window.location.reload();
    })
  }

  return (
    <Box>
        <Box marginTop={"50px"}>
          <h1 className="text-5xl"> Bienvenue sur votre profil étudiant. </h1>
          <p className="ms-2  text-gray-500 ">veuillez compléter votre profil.</p>
        </Box>
        <Box marginTop={"50px"}>
          <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                if (isStepOptional(index)) {
                  labelProps.optional = (
                    <Typography variant="caption">Optional</Typography>
                  );
                }
                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                }
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography 
                  component={"span"}
                  sx={{ mt: 2, mb: 1 }}>
                  All steps completed - you&apos;re finished
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Box sx={{ flex: '1 1 auto' }} />
                  <Button onClick={handleReset}>Reset</Button>
                </Box>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Typography component={"span"} sx={{ mt: 2, mb: 1 }}>
                  {stepsComponents[activeStep]}
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                  <Box sx={{ flex: '1 1 auto' }} />
                  {isStepOptional(activeStep) && (
                    <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                      Skip
                    </Button>
                  )}

                  <Button onClick={handleNext}>
                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                  </Button>
                </Box>
              </React.Fragment>
            )}
          </Box>
        </Box>
    </Box>
  );
}