import { 
    Box, 
    Button, 
    Modal, 
    TextField, 
    Select, 
    MenuItem, 
    OutlinedInput } from '@mui/material';
import { useState } from 'react';
import { backendApi } from '../../api';
import toast from "react-hot-toast";
import { getAllormation } from '../services/formations.services';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

const updateFormationStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-35%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    borderRadius : "10px",
    p: 4,
};

export default function UpdateFormationModal({handleModalClose, modalOpen, setFormations, formation}) {
    const [info, setInfo] = useState(formation)
    const handleUpdateFormation = async () => {
        try {
            const data = {...info, formationID : formation._id}
            const res = await backendApi.post("/formations/update/", data);
            toast.success(res.data.message)
            const response = await getAllormation();
            setFormations(response);
            handleModalClose(true)
        } catch (err) {
            toast.err(err)
        }
    }
    return (
        <Modal 
            keepMounted
            open={modalOpen}
            onClose={handleModalClose}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
        >
           <Box sx={updateFormationStyle}>
                <h2>Modifier les informations de cet formation</h2>
                <Box>
                    <Box marginTop={2}  sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                        <label htmlFor="title">Titre</label>
                        <TextField 
                            id="title" 
                            variant="outlined" 
                            label="Titre"
                            value={info.name} 
                            onChange={(e) => setInfo({ ...info, name: e.target.value })}
                        />
                    </Box>
                    <Box marginTop={2} sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                        <label htmlFor="description">Description</label>
                        <TextField 
                            id="description" 
                            label="Description"
                            variant="outlined" 
                            value={info.description} 
                            onChange={(e) => setInfo({ ...info, description: e.target.value })} 
                        />
                    </Box>
                    <Box marginTop={2} sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                        <label htmlFor="price">Prix de la formation</label>
                        <TextField 
                            id="price" 
                            label="Prix"
                            variant="outlined" 
                            value={info.price} 
                            onChange={(e) => setInfo({ ...info, price: e.target.value })} 
                        />
                    </Box>
                    <Box marginTop={2} sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                        <label htmlFor="duration">Durée de la formation</label>
                        <Select 
                            id="duration"
                            variant="outlined"
                            label="Durée"
                            onChange={(e) => setInfo({ ...info, duration: e.target.value })}
                            value={info.duration}
                            fullWidth={true}
                            input={<OutlinedInput label="Name" />}
                            MenuProps={MenuProps}
                        >
                                {
                                    Array.from({ length: 12 }, (_, i) => i + 1).map((i) => (
                                        <MenuItem key={i} value={i}>{i} mois</MenuItem>
                                    ))
                                }
                        </Select>
                    </Box>
                    <Button
                        onClick={handleUpdateFormation}
                        variant="contained"
                        style={{marginTop: "20px"}}
                        fullWidth
                    >
                    Enregistrer</Button>
                </Box>
           </Box>
        </Modal>
    );
}